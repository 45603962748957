import { AxiosError } from 'axios';
import { LOCAL_STORAGE_KEY_REDIRECT_URL, LOCAL_STORAGE_KEY_USUARIO_ID, setLocalStorage } from '../../../../contexts';

export const errorInterceptor = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.response?.status === 401) {
    const userIdNotZero = localStorage.getItem(LOCAL_STORAGE_KEY_USUARIO_ID) !== '0';
    setLocalStorage();

    localStorage.setItem(LOCAL_STORAGE_KEY_REDIRECT_URL, window.location.pathname);

    const pagesToNotRedirect = ['/home', '/'];

    if (!pagesToNotRedirect.includes(window.location.pathname) || userIdNotZero) {
      window.location.href = window.location.origin + '/home';
    }
  }

  if (error.response?.status === 403) {
    window.location.href = window.location.origin + + '/sem-permissao';
  }

  return Promise.reject(error);
};