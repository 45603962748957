import { Box, Chip, Dialog } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../shared/components/MUI/button/Button';
import { LOCAL_STORAGE_KEY_USUARIO_EMAIL_CONFIRMADO, useUserContext } from '../../shared/contexts';
import { feedback, usuarioService } from '../../shared/services';

export const ConfirmEmail: React.FC = () => {
  const [message, setMessage] = useState<string>();

  const { email, token } = useParams<{ email: string, token: string }>();

  const navigate = useNavigate();
  const { setEmailConfirmado } = useUserContext();

  const { mutate, isLoading } = useMutation((params: { email: string, token: string }) =>
    usuarioService.confirmEmail(params.email, params.token), {
    onSuccess: () => {
      localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_EMAIL_CONFIRMADO, JSON.stringify(true));
      setEmailConfirmado(true);
      feedback('E-mail confirmado!', 'success');
      navigate('/home');
    },
    onError: () => {
      setMessage('Um erro ocorreu, tente novamente!');
    },
  });

  useEffect(() => {
    email && token && mutate({ email, token });
  }, [email, token]);

  const hasError = message?.includes('erro');

  const chipColor = () => {
    if (!message) {
      return 'warning';
    }

    if (hasError) {
      return 'error';
    }

    return 'success';
  };

  return (
    <Dialog open>
      <Box
        width={400}
        height={500}
        padding={4}
        gap={2}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Chip label={message ?? 'Carregando...'} color={chipColor()} />

        <Button
          label='Tentar novamente'
          disabled={!hasError}
          isLoading={isLoading}
          variant='contained'
          onClick={() => window.location.reload()}
        />
      </Box>
    </Dialog>
  );
};
