import { Box, Card, LinearProgress, TextField, useTheme } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import dayjs, { Dayjs } from 'dayjs';
import { MuiColorInput } from 'mui-color-input';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { ProfissionalEstabelecimentoServico } from '../../../shared/components/SconApp/profissionalEstabelecimentoServico/ProfissionalEstabelecimentoServico';
import { Button } from '../../../shared/components/MUI/button/Button';
import { useUserContext } from '../../../shared/contexts';
import { VTextField } from '../../../shared/forms';
import { VTextFieldPercentage } from '../../../shared/forms/VTextFieldPercentage';
import getValidationErrors from '../../../shared/helpers/getValidationErrors';
import { LayoutCadastro } from '../../../shared/layouts';
import { ICreateAdministradorEstabelecimento, ICreateProfissional, ICreateProfissionalEstabelecimento, IProfissionalEstabelecimentoServico, IProfissionalEstabelecimentoServicoView, IUpdateProfissionalEstabelecimento, administradorEstabelecimentoService, profissionalEstabelecimentoService, profissionalService } from '../../../shared/services';
import { feedback, finalizarRequisicao } from '../../../shared/services/alertService';
import { IPropsEstabelecimentoPagina } from '../Estabelecimento';

const schema = Yup.object().shape({
  nome: Yup.string().required()
});

const schemaComissao = Yup.object().shape({
  comissao: Yup.number().required().min(0).max(100)
});

interface IProps extends IPropsEstabelecimentoPagina {
  profissionalEstabelecimentoId?: number;
  isCreateAdministradorEstabelecimento: boolean;
  onCloseEdit: () => void;
}

export const EstabelecimentoProfissionaisCadastro: React.FC<IProps> = ({ estabelecimento, refetch, onCloseEdit, profissionalEstabelecimentoId, isCreateAdministradorEstabelecimento }) => {
  const [profissionalServicos, setProfissionalServicos] = useState<{ [key: number]: IProfissionalEstabelecimentoServicoView[] }>({ [estabelecimento.id]: [] });
  const [horaInicio, setHoraInicio] = useState<Dayjs>();
  const [horaFim, setHoraFim] = useState<Dayjs>();

  const theme = useTheme();
  const [color, setColor] = useState<string>(theme.palette.primary.main);

  const { usuarioId, nomeUsuario } = useUserContext();

  const formRef = useRef<FormHandles>(null);

  const { data: profissionalEstabelecimento, isLoading: isLoadingProfissional } = useQuery({
    queryKey: ['profissionalEstabelecimento'],
    queryFn: () => {
      if (profissionalEstabelecimentoId) {
        return profissionalEstabelecimentoService.getById(profissionalEstabelecimentoId);
      }
    },
    enabled: !!profissionalEstabelecimentoId,
  });

  useEffect(() => {
    if (profissionalEstabelecimento) {
      formRef.current?.setData(profissionalEstabelecimento);
      setProfissionalServicos((prevState) => ({
        ...prevState,
        [estabelecimento.id]: [
          ...(prevState[estabelecimento.id] || []),
          ...(profissionalEstabelecimento.profissionalEstabelecimentoServicos || []),
        ],
      }));
      setHoraInicio(dayjs(profissionalEstabelecimento.horaInicio, 'HH:mm'));
      setHoraFim(dayjs(profissionalEstabelecimento.horaFim, 'HH:mm'));
      setColor(profissionalEstabelecimento.cor);
    }
  }, [profissionalEstabelecimento]);

  const profissionalEstabelecimentoServicos = useMemo(() => profissionalServicos && profissionalServicos[estabelecimento.id].map(item => (
    {
      profissionalEstabelecimentoId: item.profissionalEstabelecimentoId,
      servicoId: item.servicoId,
      duracaoServico: item.duracaoServico
    }) as IProfissionalEstabelecimentoServico
  ), [profissionalServicos]);

  const { mutate: createProfissional, isLoading: isLoadingCreateProfissional } = useMutation((profissional: ICreateProfissional) =>
    profissionalService.create(profissional), {
    onSuccess: () => finalizarRequisicao('Profissional criado', 'success', () => {
      refetch();
      onCloseEdit();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const { mutate: createProfissionalEstabelecimento, isLoading: isLoadingCreateProfissionalEstabelecimento } = useMutation((profissionalEstabelecimento: ICreateProfissionalEstabelecimento) =>
    profissionalEstabelecimentoService.create(profissionalEstabelecimento), {
    onSuccess: () => finalizarRequisicao('Profissional criado', 'success', () => {
      refetch();
      onCloseEdit();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const { mutate: updateProfissionalEstabelecimento, isLoading: isLoadingUpdateProfissionalEstabelecimento } = useMutation((profissionalEstabelecimento: IUpdateProfissionalEstabelecimento) =>
    profissionalEstabelecimentoService.update(profissionalEstabelecimento), {
    onSuccess: () => finalizarRequisicao('Profissional editado', 'success', () => {
      refetch();
      onCloseEdit();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const { mutate: createAdministradorEstabelecimento, isLoading: isLoadingCreateAdministradorEstabelecimento } = useMutation((administradorEstabelecimento: ICreateAdministradorEstabelecimento) =>
    administradorEstabelecimentoService.create(administradorEstabelecimento), {
    onSuccess: () => finalizarRequisicao('Profissional agora é administrador.', 'success', () => {
      refetch();
      onCloseEdit();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const onClickCriarAdministrador = useCallback(() => {
    if (profissionalEstabelecimento?.profissional) {
      const newAdministradorEstabelecimento: ICreateAdministradorEstabelecimento = {
        usuarioId: profissionalEstabelecimento?.profissional?.usuarioId,
        estabelecimentoId: estabelecimento.id
      };

      createAdministradorEstabelecimento(newAdministradorEstabelecimento);
    }
  }, [profissionalEstabelecimento]);


  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      if (!profissionalEstabelecimento?.profissionalId && !isCreateAdministradorEstabelecimento) {
        await schema.validate({ nome: data.nome }, { abortEarly: false });
      }

      if (!horaInicio || !horaFim) {
        feedback('Os campos horário de início e horário de término são obrigatórios!', 'error');
        return;
      }

      if (horaInicio >= horaFim) {
        feedback('O horário de inicio deve ser menor que o horário de termino.', 'error');
        return;
      }

      const stringHoraInicio = horaInicio.format('HH:mm:ss');
      const stringHoraFim = horaFim.format('HH:mm:ss');

      if (stringHoraInicio < estabelecimento.horaInicio || stringHoraFim > estabelecimento.horaFim) {
        feedback(`O horário do profissional deve estar entre ${estabelecimento.horaInicio.substring(0, 5)} e ${estabelecimento.horaFim.substring(0, 5)}, que são os horários cadastrados no estabelecimento.`, 'error');
        return;
      }

      await schemaComissao.validate({ comissao: data.comissao }, { abortEarly: false });

      if (profissionalEstabelecimento) {
        const submitData: IUpdateProfissionalEstabelecimento = {
          id: profissionalEstabelecimento.id,
          nome: data.nome,
          profissionalId: profissionalEstabelecimento.profissionalId,
          estabelecimentoId: profissionalEstabelecimento.estabelecimentoId,
          horaInicio: stringHoraInicio,
          horaFim: stringHoraFim,
          comissao: data.comissao,
          cor: color,
          profissionalEstabelecimentoServicos: profissionalEstabelecimentoServicos,
        };

        updateProfissionalEstabelecimento(submitData);
        return;
      }

      const submitData: ICreateProfissionalEstabelecimento = {
        estabelecimentoId: estabelecimento.id,
        nome: data.nome,
        horaInicio: stringHoraInicio,
        horaFim: stringHoraFim,
        comissao: data.comissao,
        cor: color,
        profissionalEstabelecimentoServicos: profissionalEstabelecimentoServicos,
      };

      if (isCreateAdministradorEstabelecimento) {
        const profissional: ICreateProfissional = {
          usuarioId: usuarioId,
          profissionalEstabelecimento: submitData
        };

        createProfissional(profissional);
        return;
      }

      createProfissionalEstabelecimento(submitData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, [profissionalEstabelecimento, profissionalEstabelecimentoServicos, horaInicio, horaFim, color]);

  const nome = useMemo(() => profissionalEstabelecimento?.nome || nomeUsuario, [profissionalEstabelecimento, nomeUsuario]);

  const canBeAdmin = useMemo(() => profissionalEstabelecimento?.profissional && !estabelecimento.administradores.map(x => x.usuarioId).includes(profissionalEstabelecimento.profissional.usuarioId), [profissionalEstabelecimento]);

  useEffect(() => { console.log(color); }, [color]);

  return !isLoadingProfissional || !profissionalEstabelecimentoId ? (
    <Box>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <LayoutCadastro header={`${profissionalEstabelecimentoId ? 'Editar' : 'Novo'} profissional`} isSubmit isLoading={isLoadingCreateProfissionalEstabelecimento || isLoadingUpdateProfissionalEstabelecimento || isLoadingCreateProfissional}>
          <Box display='flex' flexDirection='column' gap={2}>
            {isCreateAdministradorEstabelecimento || profissionalEstabelecimento?.profissionalId ?
              <TextField value={nome} disabled />
              : <VTextField name='nome' label='Nome profissional' />
            }

            <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
              <TimePicker
                label='Horário de início'
                sx={{ width: '100%' }}
                ampm={false}
                value={horaInicio}
                onChange={(value: any) => setHoraInicio(value)}
              />

              <TimePicker
                label='Horário de termino'
                sx={{ width: '100%' }}
                ampm={false}
                value={horaFim}
                onChange={(value: any) => setHoraFim(value)}
              />
            </Box>

            <VTextFieldPercentage
              label='Comissão'
              name='comissao'
            />

            <MuiColorInput label='Cor do profissional' format="hex" value={color} onChange={(value) => setColor(value)} />

            <ProfissionalEstabelecimentoServico
              profissionalEstabelecimentoId={profissionalEstabelecimentoId}
              estabelecimentoId={estabelecimento.id}
              servicos={estabelecimento.servicos}
              profissionalServicos={profissionalServicos}
              setProfissionalServicos={setProfissionalServicos}
            />

            {canBeAdmin &&
              <Button
                variant='contained'
                label='Tornar administrador'
                onClick={onClickCriarAdministrador}
                isLoading={isLoadingCreateAdministradorEstabelecimento}
                disabled={isLoadingCreateAdministradorEstabelecimento}
              />}
          </Box>
        </LayoutCadastro>
      </Form>
    </Box>
  ) : (
    <Card sx={{ width: 400, height: 200 }}>
      <LinearProgress />
    </Card>
  );
};