import React, { useEffect, useState } from 'react';
import { TVTextFieldProps } from '.';
import { TextField } from '@mui/material';
import { useField } from '@unform/core';

const PhoneFormat = (value: string): string => {
  const cleanValue = value.replace(/\D/g, '');
  const ddd = cleanValue.slice(0, 2);
  const firstPart = cleanValue.slice(2, 7);
  const secondPart = cleanValue.slice(7, 11);

  let formattedValue = '';

  if (ddd) {
    formattedValue += `(${ddd})`;
  }

  if (firstPart) {
    formattedValue += ` ${firstPart}`;
  }

  if (secondPart) {
    formattedValue += `-${secondPart}`;
  }

  return formattedValue;
};

export const VTextFieldPhone: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const [value, setValue] = useState(defaultValue || '');
  const [notMaxLength, setNotMaxLength] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value.replace(/\D/g, ''),
      setValue: (_, newValue) => setValue(newValue),
      clearValue: () => setValue('')
    });
  }, [registerField, fieldName, value]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setValue(PhoneFormat(value));

    if (value.replace(/\D/g, '').length > 0
      && value.replace(/\D/g, '').length < 11) {
      setNotMaxLength(true);
    }
    else{
      setNotMaxLength(false);
    }

    rest.onChange?.(event);
  };

  return (
    <TextField
      {...rest}
      fullWidth
      error={!!error || notMaxLength}
      helperText={error || (notMaxLength && 'Preencha completamente o número de telefone.')}
      value={value}
      onChange={handlePhoneChange}
      onKeyDown={(e) => { error && clearError(); rest.onKeyDown?.(e); }}
      inputProps={{ maxLength: 15 }}
    />
  );
};
