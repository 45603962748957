import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { IAgendamentoView, agendamentoService } from '../../../../services';
import { SchedulerFilterOptionsEnum } from '../Scheduler';
import { Event } from './Event';

const HOUR_HEIGHT = 100;
const HEADER_HEIGHT = 60;

interface IProps {
  estabelecimentoId: number | undefined;
  profissionalId?: number | undefined;
  filterOptionType: number;
  option: { id: number, nome: string } | undefined;
  date: Date;
  horaInicio: number;
  horarios: string[];
  refetch: () => any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WeekView: React.FC<IProps> = ({
  estabelecimentoId,
  profissionalId,
  filterOptionType,
  option,
  date,
  horaInicio,
  horarios,
  refetch,
  setIsLoading
}) => {
  const theme = useTheme();

  const daysOfWeek = [
    { id: 0, name: 'DOM' },
    { id: 1, name: 'SEG' },
    { id: 2, name: 'TER' },
    { id: 3, name: 'QUA' },
    { id: 4, name: 'QUI' },
    { id: 5, name: 'SEX' },
    { id: 6, name: 'SÁB' },
  ];

  const getDaysOfWeekFromCurrent = (): Date[] => {
    const days = Array(7).fill(new Date(date)).map((value, index) =>
      new Date(value.setDate(value.getDate() - value.getDay() + index)));

    return days;
  };

  const { data: agendamentos, isFetching } = useQuery(
    ['agendamentos', estabelecimentoId, profissionalId, option, date],
    () => agendamentoService.get(
      filterOptionType === SchedulerFilterOptionsEnum.Estabelecimento ? option?.id : estabelecimentoId,
      filterOptionType === SchedulerFilterOptionsEnum.Profissional ? option?.id : undefined,
      undefined,
      getDaysOfWeekFromCurrent()[0],
      getDaysOfWeekFromCurrent()[6]),
    {
      enabled: !!date && (!!estabelecimentoId || !!profissionalId)
    }
  );

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const sortedAgendamentos = useMemo(() => agendamentos?.sort((a: IAgendamentoView, b: IAgendamentoView) => (a.data < b.data ? -1 : 1)) || [], [agendamentos]);

  const agendamentosByDayOfWeek = useMemo((): IAgendamentoView[][] => {
    const separated: IAgendamentoView[][] = Array.from({ length: 7 }, () => []);

    sortedAgendamentos &&
      sortedAgendamentos.forEach((agendamento) => {
        const diaSemana = new Date(agendamento.data).getDay();

        if (diaSemana >= 0 && diaSemana < 7) {
          separated[diaSemana].push(agendamento);
        }
      });

    return separated;
  }, [sortedAgendamentos]);

  const heights: { [id: number]: number } = useMemo(() => {
    if (!sortedAgendamentos) return {};

    return sortedAgendamentos.reduce((acc, event) => {
      const [horasInicio, minutosInicio] = event.horaInicio.split(':').map(Number);
      const [horasFim, minutosFim] = event.horaFim.split(':').map(Number);

      const totalMinInicio = horasInicio * 60 + minutosInicio;
      const totalMinFim = horasFim * 60 + minutosFim;
      const totalMin = totalMinFim - totalMinInicio;

      const height = (totalMin / 15) * (HOUR_HEIGHT / 4);

      acc[event.id] = height;

      return acc;
    }, {} as { [id: number]: number });
  }, [sortedAgendamentos]);

  const previousEvents: { [dayOfWeek: number]: { [id: number]: IAgendamentoView[] } } = useMemo(() => {
    if (!agendamentosByDayOfWeek) return {};

    return agendamentosByDayOfWeek.map((dayAgendamentos) => {
      return dayAgendamentos.reduce((acc, currentEvent, currentIndex) => {
        const eventsSameTimeOnly: IAgendamentoView[] = [];

        const eventsSameTime = dayAgendamentos.filter((event, index) =>
          currentIndex > index
          && event.horaInicio < currentEvent.horaFim
          && event.horaFim > currentEvent.horaInicio
        );

        eventsSameTime.forEach((item) => {
          if (acc[item.id].length === 0 || acc[item.id].some(x => x.horaFim > currentEvent.horaInicio)) {
            eventsSameTimeOnly.push(item);
          }
        });

        acc[currentEvent.id] = eventsSameTimeOnly;

        return acc;
      }, {} as { [id: number]: IAgendamentoView[] });
    });
  }, [agendamentosByDayOfWeek]);

  return (
    <Box display='flex'>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='top'
          gap={2}
          style={{
            width: '80px',
            height: HEADER_HEIGHT,
          }}
        >
        </Box>

        {horarios.map((horario) => (
          <Box
            key={horario}
            display='flex'
            justifyContent='center'
            alignItems='top'
            gap={2}
            style={{
              width: '80px',
              height: HOUR_HEIGHT / 2,
            }}
          >
            <Typography>{horario}</Typography>
            <Divider variant='middle' orientation='vertical' flexItem />
          </Box>
        ))}
      </Box>

      {getDaysOfWeekFromCurrent().map((day, index) => (
        <Box key={index} flex={1}>
          <Box
            height={HEADER_HEIGHT}
            width={HEADER_HEIGHT}
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            m='auto'
            borderRadius='50%'
            sx={{
              backgroundColor: day.toDateString() === new Date().toDateString() ? theme.palette.primary.main : 'none'
            }}
          >
            <Typography
              variant='h6'
            >
              {day.getDate()}
            </Typography>

            <Typography variant='caption' m={0}>
              {daysOfWeek[index].name}
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
          </Box>

          <Box width='100%' position='relative'>
            {agendamentosByDayOfWeek[index]
              .sort((a: IAgendamentoView, b: IAgendamentoView) => (a.data < b.data ? -1 : 1))
              .map((agendamento: IAgendamentoView) => (
                <Event
                  key={agendamento.id}
                  firstTime={horaInicio}
                  currentEvent={agendamento}
                  previousEvents={previousEvents[index]}
                  heights={heights}
                  hourHeight={HOUR_HEIGHT}
                  maxLengthDescription={10}
                  refetch={refetch}
                />
              ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
