import { Box, Divider, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { IAgendamentoView, agendamentoService } from '../../../../services/api/agendamentoService';
import { SchedulerFilterOptionsEnum } from '../Scheduler';
import { Event } from './Event';

const HOUR_HEIGHT = 100;

interface IProps {
  estabelecimentoId: number | undefined;
  profissionalId: number | undefined;
  filterOptionType: number;
  option: { id: number, nome: string } | undefined;
  date: Date;
  horaInicio: number;
  horarios: string[];
  refetch: () => any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DayView: React.FC<IProps> = ({
  estabelecimentoId,
  profissionalId,
  filterOptionType,
  option,
  date,
  horaInicio,
  horarios,
  refetch,
  setIsLoading
}) => {
  const { data: agendamentos, isFetching } = useQuery(
    ['agendamentos', estabelecimentoId, profissionalId, option, date],
    () => agendamentoService.get(
      filterOptionType === SchedulerFilterOptionsEnum.Estabelecimento ? option?.id : estabelecimentoId,
      filterOptionType === SchedulerFilterOptionsEnum.Profissional ? option?.id : undefined,
      profissionalId,
      date,
      date),
    {
      enabled: !!date && (!!estabelecimentoId || !!profissionalId)
    }
  );

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const sortedAgendamentos = useMemo(() => agendamentos?.sort((a: IAgendamentoView, b: IAgendamentoView) => (a.data < b.data ? -1 : 1)) || [], [agendamentos]);

  const heights: { [id: number]: number } = useMemo(() => {
    if (!sortedAgendamentos) return {};

    return sortedAgendamentos.reduce((acc, event) => {
      const [horasInicio, minutosInicio] = event.horaInicio.split(':').map(Number);
      const [horasFim, minutosFim] = event.horaFim.split(':').map(Number);

      const totalMinInicio = horasInicio * 60 + minutosInicio;
      const totalMinFim = horasFim * 60 + minutosFim;
      const totalMin = totalMinFim - totalMinInicio;

      const height = (totalMin / 15) * (HOUR_HEIGHT / 4);

      acc[event.id] = height;

      return acc;
    }, {} as { [id: number]: number });
  }, [sortedAgendamentos]);

  const previousEvents: { [id: number]: IAgendamentoView[] } = useMemo(() => {
    if (!sortedAgendamentos) return {};

    return sortedAgendamentos.reduce((acc, currentEvent, currentIndex) => {
      const eventsSameTimeOnly: IAgendamentoView[] = [];

      const eventsSameTime = sortedAgendamentos.filter((event, index) =>
        currentIndex > index
        && event.horaInicio < currentEvent.horaFim
        && event.horaFim > currentEvent.horaInicio
      );

      eventsSameTime.forEach((item) => {
        if (acc[item.id].length == 0 || acc[item.id].some(x => x.horaFim > currentEvent.horaInicio)) {
          eventsSameTimeOnly.push(item);
          return;
        }
      });

      acc[currentEvent.id] = eventsSameTimeOnly;

      return acc;
    }, {} as { [id: number]: IAgendamentoView[] });
  }, [sortedAgendamentos]);

  return (
    <Grid container>
      <Grid item xs={3} lg={1} display='flex' flexDirection='column' alignItems='flex-start'>
        {horarios.map((horario) => (
          <Box
            key={horario}
            display='flex'
            width='auto'
            gap={2}
            height={HOUR_HEIGHT / 2}
          >
            <Typography>{horario}</Typography>

            <Divider variant='middle' orientation='vertical' flexItem />
          </Box>
        ))}
      </Grid>

      <Grid item xs={9} lg={11} position='relative'>
        {sortedAgendamentos &&
          sortedAgendamentos
            .map((agendamento: IAgendamentoView) => (
              <Event
                key={agendamento.id}
                firstTime={horaInicio}
                currentEvent={agendamento}
                heights={heights}
                previousEvents={previousEvents}
                hourHeight={HOUR_HEIGHT}
                maxLengthDescription={100}
                refetch={refetch}
              />
            ))}
      </Grid>
    </Grid>
  );
};