import { Box, Card, LinearProgress } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { VTextField } from '../../../shared/forms';
import getValidationErrors from '../../../shared/helpers/getValidationErrors';
import { LayoutCadastro } from '../../../shared/layouts';
import { ICreateProduto, IUpdateProduto, produtoService } from '../../../shared/services';
import { AlertTypes, feedback } from '../../../shared/services/alertService';

const schema = Yup.object().shape({
  descricao: Yup.string().required(),
  valor: Yup.number().required()
});

interface IProps {
  estabelecimentoId: number;
  produtoId?: number;
  refetch: () => void;
  onCloseEdit: () => void;
}

export const EstabelecimentoProdutosCadastro: React.FC<IProps> = ({ estabelecimentoId, produtoId, refetch, onCloseEdit }) => {
  const { data: produto, isLoading: isLoadingProduto } = useQuery({
    queryKey: ['produto'],
    queryFn: () => {
      if (produtoId) {
        return produtoService.getById(produtoId);
      }
    },
    enabled: !!produtoId,
  });

  const { mutate: incluirProduto, isLoading: isLoadingIncluirProduto } = useMutation((produto: ICreateProduto) =>
    produtoService.create(produto), {
    onSuccess: () => finalizarRequisicao('Produto criado', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const { mutate: editarProduto, isLoading: isLoadingEditarProduto } = useMutation((produto: IUpdateProduto) =>
    produtoService.update(produto), {
    onSuccess: () => finalizarRequisicao('Produto editado', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const finalizarRequisicao = (mensagem: string, tipoFeedback: AlertTypes) => {
    feedback(mensagem, tipoFeedback);

    if (tipoFeedback === 'success') {
      refetch();
      onCloseEdit();
    }
  };

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    produto && formRef.current?.setData(produto);
  }, [produto]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate({ descricao: data.descricao, valor: data.valor }, { abortEarly: false });

        if (produtoId) {
          const submitData: IUpdateProduto = {
            id: produtoId,
            estabelecimentoId: estabelecimentoId,
            descricao: data.descricao,
            valor: data.valor
          };

          editarProduto(submitData);
          return;
        }

        const submitData: ICreateProduto = {
          estabelecimentoId: estabelecimentoId,
          descricao: data.descricao,
          valor: data.valor
        };

        incluirProduto(submitData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err as Yup.ValidationError);
          formRef.current?.setErrors(errors);
        }
      }
    }, []);

  return !isLoadingProduto || !produtoId ? (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <LayoutCadastro header={!produto ? 'Novo produto' : 'Editar produto'} isSubmit isLoading={isLoadingIncluirProduto || isLoadingEditarProduto}>
        <Box display='flex' flexDirection='column' gap={2}>
          <VTextField name='descricao' label='Descrição' />
          <VTextField name='valor' label='Valor (R$)' type='number' isMinZero />
        </Box>
      </LayoutCadastro>
    </Form>
  ) : (
    <Card sx={{ width: 400, height: 200 }}>
      <LinearProgress />
    </Card>
  );
};