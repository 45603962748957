import { Box, Card, Icon, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface IItem {
  id: number;
  descricao: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

interface IProps {
  lista: IItem[];
  editOnClick?: boolean;
  editIcon?: string;
  onClickEdit?: (id: number) => void;
  onClickDelete?: (id: number) => void;
  bgColor?: string;
  bgColorOnHover?: string;
  hover?: boolean;
}

export const Listagem: React.FC<IProps> = ({
  lista,
  editOnClick = false,
  editIcon = 'edit',
  onClickEdit,
  onClickDelete,
  bgColor = 'none',
  hover = false,
  bgColorOnHover = bgColor
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={0.5}
    >
      {lista.map((item) =>
        <Card
          variant='elevation'
          key={item.id}
          sx={{
            backgroundColor: bgColor
          }}
        >
          <Box
            onClick={() => editOnClick && onClickEdit && onClickEdit(item.id)}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            padding={2}
            gap={2}
            sx={{
              backgroundColor: bgColor,
              ':hover': { backgroundColor: hover ? bgColorOnHover : bgColor, cursor: hover ? 'pointer' : 'inherit' }
            }}
          >
            <Box
              display='flex'
              alignItems='center'
              gap={2}
            >
              {item.startAdornment}

              <Typography>{item.descricao}</Typography>

              {item.endAdornment}
            </Box>

            <Box>
              {onClickEdit &&
                <IconButton onClick={() => onClickEdit(item.id)} disabled={item.disabled}>
                  <Icon>{editIcon}</Icon>
                </IconButton>
              }

              {onClickDelete &&
                <IconButton onClick={() => onClickDelete(item.id)} disabled={item.disabled}>
                  <Icon>delete</Icon>
                </IconButton>
              }
            </Box>
          </Box>
        </Card>
      )}
    </Box>
  );
};