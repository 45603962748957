import { Box, Dialog, DialogContent } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { feedback, finalizarRequisicao } from '../../../services';
import { Button } from '../../MUI/button/Button';

interface PhotoUploadProps {
  open: boolean;
  id: number;
  refetch: () => void;
  uploadPhoto: (id: number, file: FormData) => Promise<string>;
  deletePhoto: (id: number) => Promise<string>;
  onDelete: () => void;
  onClose: () => void;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({ open, id, uploadPhoto, deletePhoto, onDelete, refetch, onClose }) => {
  const [file, setFile] = useState<any>();

  const { mutate: uploadFile, isLoading: isLoadingUploadFile } = useMutation((formData: FormData) =>
    uploadPhoto(id, formData), {
    onSuccess: () => finalizarRequisicao('Salvo com sucesso', 'success', () => {
      refetch();
      setFile(undefined);
      onClose();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const { mutate: deleteFile, isLoading: isLoadingDeleteFile } = useMutation(() =>
    deletePhoto(id), {
    onSuccess: () => finalizarRequisicao('Deletado com sucesso', 'success', () => {
      refetch();
      setFile(undefined);
      onClose();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const handleChangeInputFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = useCallback(async () => {
    if (!file) {
      feedback('Um arquivo deve ser escolhido!', 'error');
      return;
    }

    const formData = new FormData();

    formData.append('file', file);

    try {
      uploadFile(formData);
    } catch (error) {
      console.error('Erro ao salvar foto.', error);
    }
  }, [file]);

  const handleDelete = useCallback(async () => {
    onDelete();

    try {
      deleteFile();
    } catch (error) {
      console.error('Erro ao deletar foto.', error);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <input type="file" onChange={handleChangeInputFile} />

        <Box
          display='flex'
          justifyContent='center'
          marginTop={2}
          gap={2}
        >
          <Button
            label='Salvar'
            isLoading={isLoadingUploadFile}
            onClick={handleUpload}
            variant='contained'
          />

          <Button
            label='Excluir foto'
            isLoading={isLoadingDeleteFile}
            onClick={handleDelete}
            variant='contained'
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PhotoUpload;
