import { Api } from './axios-config';
import { IEstabelecimentoView } from './estabelecimentoService';
import { IServicoView } from './servicoService';
import { IUsuarioView } from './usuarioService';

export interface IProfissionalView {
  id: number;
  usuarioId: number;
  usuario: IUsuarioView;
  profissionalEstabelecimentos: IProfissionalEstabelecimentoView[];
}

export interface IProfissionalEstabelecimentoView {
  id: number;
  nome: string;
  foto?: string;
  horaInicio: string;
  horaFim: string;
  comissao: number;
  cor: string;
  estabelecimentoId: number;
  estabelecimento: IEstabelecimentoView;
  profissionalId?: number;
  profissional?: IProfissionalView;
  profissionalEstabelecimentoServicos?: IProfissionalEstabelecimentoServicoView[];
}

export interface IProfissionalEstabelecimentoServicoView {
  id: number;
  profissionalEstabelecimentoId: number;
  servicoId: number;
  duracaoServico: number;
  servico: IServicoView;
}

const getById = async (id: number): Promise<IProfissionalView> => {
  try {
    const { data } = await Api.get<IProfissionalView>(
      `/profissional/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter profissional.');
  }
};

const getByUserId = async (usuarioId: number): Promise<IProfissionalView> => {
  try {
    const { data } = await Api.get<IProfissionalView>(
      `/profissional/usuario/${usuarioId}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter profissional por usuário.');
  }
};

const getByProfissionalEstabelecimentoId = async (profissionalEstabelecimentoId: number): Promise<IProfissionalView> => {
  try {
    const { data } = await Api.get<IProfissionalView>(
      `/profissional/profissional-estabelecimento/${profissionalEstabelecimentoId}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter profissional.');
  }
};

const get = async (
  estabelecimentoId?: number,
  servicoId?: number
): Promise<IProfissionalView[]> => {
  try {
    const queryParams = {
      estabelecimentoId: estabelecimentoId?.toString(),
      servicoId: servicoId?.toString()
    };

    const { data } = await Api.get('/profissional', {
      params: queryParams,
    });

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter profissionais.');
  }
};

export interface ICreateProfissional {
  usuarioId: number;
  profissionalEstabelecimento: ICreateProfissionalEstabelecimento;
}

export interface ICreateProfissionalEstabelecimento {
  estabelecimentoId: number;
  nome?: string;
  foto?: string;
  horaInicio: string;
  horaFim: string;
  comissao: number;
  cor: string;
  profissionalEstabelecimentoServicos?: IProfissionalEstabelecimentoServico[];
}

const create = async (body: ICreateProfissional): Promise<number> => {
  try {
    const { data } = await Api.post(
      '/profissional',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export interface IUpdateProfissional {
  id: number;
  usuarioId: number;
  profissionalEstabelecimentos: IUpdateProfissionalEstabelecimento[];
}

export interface IUpdateProfissionalEstabelecimento {
  id: number;
  nome?: string;
  foto?: string;
  profissionalId?: number;
  estabelecimentoId: number;
  horaInicio: string;
  horaFim: string;
  comissao: number;
  cor: string;
  profissionalEstabelecimentoServicos?: IProfissionalEstabelecimentoServico[];
}

export interface IProfissionalEstabelecimentoServico {
  profissionalEstabelecimentoId?: number;
  servicoId: number;
  duracaoServico: number;
}

const update = async (body: IUpdateProfissional) => {
  try {
    const { data } = await Api.put(
      '/profissional',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: number) => {
  try {
    await Api.delete(
      `/profissional/${id}`
    );

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export const profissionalService = {
  getById,
  getByUserId,
  getByProfissionalEstabelecimentoId,
  get,
  create,
  update,
  remove
};