import { Api } from './axios-config';
import { IAgendamentoView } from './agendamentoService';

export interface IUsuarioView {
  id: number;
  nome: string;
  primeiroNome: string;
  email: string;
  cpf?: string;
  telefone?: string;
  sexo: number;
  tipos: number[];
  foto?: string;
  emailConfirmado: boolean;
  agendamentos: IAgendamentoView[];
}

const getById = async (id: number): Promise<IUsuarioView> => {
  try {
    const { data } = await Api.get<IUsuarioView>(
      `/usuario/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter usuário.');
  }
};

const obterPorEmail = async (email: string): Promise<IUsuarioView> => {
  try {
    const { data } = await Api.get<IUsuarioView>(
      `/usuario?email=${email}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter usuário.');
  }
};

const get = async (naoAdministradorEstabelecimentos: boolean): Promise<IUsuarioView[]> => {
  try {
    const queryParams = {
      naoAdministradorEstabelecimentos: naoAdministradorEstabelecimentos?.toString()
    };

    const { data } = await Api.get<IUsuarioView[]>('/usuario', {
      params: queryParams
    });

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter usuários.');
  }
};

export interface IUpdateUsuario {
  id: number;
  nome: string;
  email: string;
  cpf?: string;
  telefone?: string;
  sexo: number;
  tipos: number[];
  foto?: string;
}

const update = async (body: IUpdateUsuario) => {
  try {
    const { data } = await Api.put(
      '/usuario',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const uploadPhoto = async (id: number, file: FormData): Promise<string> => {
  try {
    const { data } = await Api.post<string>(
      `/usuario/${id}/upload`, file
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao salvar foto.');
  }
};

const deletePhoto = async (id: number): Promise<string> => {
  try {
    const { data } = await Api.get<string>(
      `/usuario/${id}/deletePhoto`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao deletar foto.');
  }
};

const confirmEmail = async (email: string, token: string): Promise<string> => {
  try {
    const { data } = await Api.get<string>(
      `/usuario/confirm-email?email=${email}&token=${token}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao salvar foto.');
  }
};

export const usuarioService = {
  update,
  obterPorEmail,
  getById,
  get,
  uploadPhoto,
  deletePhoto,
  confirmEmail
};