import { Avatar, Badge, Box, ButtonGroup, Grid, Icon, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button } from '../../shared/components/MUI/button/Button';
import PhotoUpload from '../../shared/components/SconApp/photo-upload/PhotoUpload';
import { LOCAL_STORAGE_KEY_USUARIO_FOTO, LOCAL_STORAGE_KEY_USUARIO_NOME, useUserContext } from '../../shared/contexts';
import { VTextField, VTextFieldCPF, VTextFieldPhone } from '../../shared/forms';
import getValidationErrors from '../../shared/helpers/getValidationErrors';
import { LayoutBase } from '../../shared/layouts';
import { finalizarRequisicao } from '../../shared/services/alertService';
import { authService } from '../../shared/services/api/authService';
import { IUpdateUsuario, usuarioService } from '../../shared/services/api/usuarioService';

const schema = Yup.object().shape({
  nome: Yup.string().required(),
  email: Yup.string().email().required(),
  cpf: Yup.string(),
  telefone: Yup.string()
});

export const Perfil: React.FC = () => {
  const [sexoUsuario, setSexoUsuario] = useState<number>();
  const [openDialogUploadFile, setOpenDialogUploadFile] = useState(false);

  const { fotoUsuario, setFotoUsuario, setNomeUsuario } = useUserContext();
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const formRef = useRef<FormHandles>(null);

  const { data: usuario, refetch } = useQuery(
    ['usuario'],
    () => authService.getUserFromCookie()
  );

  const { mutate: updateUsuario, isLoading: isLoadingUpdateUsuario } = useMutation((usuario: IUpdateUsuario) =>
    usuarioService.update(usuario), {
    onSuccess: () => finalizarRequisicao('Informações salvas', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate({ nome: data.nome, email: data.email, cpf: data.cpf, telefone: data.telefone }, { abortEarly: false });

      if (usuario) {
        const usuarioSubmit: IUpdateUsuario = {
          id: usuario.id,
          nome: data.nome,
          email: data.email,
          telefone: data.telefone,
          cpf: data.cpf,
          sexo: sexoUsuario || 0,
          tipos: usuario.tipos,
          foto: usuario.foto,
        };

        updateUsuario(usuarioSubmit);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, [usuario, sexoUsuario]);

  useEffect(() => {
    if (usuario) {
      setSexoUsuario(usuario.sexo);
      localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_FOTO, JSON.stringify(usuario.foto));
      setFotoUsuario(usuario.foto);
      localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_NOME, JSON.stringify(usuario.nome));
      setNomeUsuario(usuario.nome);
    }
  }, [usuario]);

  const onClose = () => {
    setOpenDialogUploadFile(false);
  };

  const onDelete = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_FOTO, '');
    setFotoUsuario('');
  };

  return (
    <LayoutBase>
      {usuario &&
        <Form ref={formRef} onSubmit={handleSubmit} initialData={usuario}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
            >
              <Paper
                variant='outlined'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  padding: 3,
                  alignItems: lgDown ? 'center' : 'inherit'
                }}
              >
                <Box>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <IconButton
                        onClick={() => setOpenDialogUploadFile(true)}
                      >
                        <Icon>photo_camera</Icon>
                      </IconButton>
                    }
                  >
                    <Avatar
                      src={fotoUsuario}
                      sx={{ height: lgDown ? 100 : 150, width: lgDown ? 100 : 150 }}
                    />
                  </Badge>
                </Box>

                <VTextField name='nome' label='Nome' />
                <VTextField name='email' label='E-mail' />

                <Box display='flex' flexDirection='row' gap={3}>
                  <VTextFieldCPF name='cpf' label='CPF' />
                  <VTextFieldPhone name='telefone' label='Telefone' />
                </Box>

                <Box display='flex' flexDirection='row' alignItems='center' gap={2}>
                  {!lgDown && <Typography>Sexo: </Typography>}
                  <ButtonGroup>
                    <Button
                      label='Masculino'
                      color='secondary'
                      size={lgDown ? 'small' : 'medium'}
                      onClick={() => setSexoUsuario(1)}
                      variant={sexoUsuario === 1 ? 'contained' : 'outlined'}
                    />
                    <Button
                      label='Nenhum'
                      color='secondary'
                      size={lgDown ? 'small' : 'medium'}
                      onClick={() => setSexoUsuario(0)}
                      variant={sexoUsuario === 0 ? 'contained' : 'outlined'}
                    />
                    <Button
                      label='Feminino'
                      color='secondary'
                      size={lgDown ? 'small' : 'medium'}
                      onClick={() => setSexoUsuario(2)}
                      variant={sexoUsuario === 2 ? 'contained' : 'outlined'}
                    />
                  </ButtonGroup>
                </Box>

                <Box display='flex' justifyContent='end'>
                  <Button
                    label='Salvar'
                    type='submit'
                    isLoading={isLoadingUpdateUsuario}
                    variant='contained'
                    minWidth={200}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <PhotoUpload
            open={openDialogUploadFile}
            id={usuario.id}
            refetch={refetch}
            uploadPhoto={usuarioService.uploadPhoto}
            deletePhoto={usuarioService.deletePhoto}
            onDelete={onDelete}
            onClose={onClose}
          />
        </Form >
      }
    </LayoutBase>
  );
};
