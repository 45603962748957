import {
  Box,
  Paper,
  Tab,
  Tabs,
  useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteConfirmationDialog } from '../../../shared/components/SconApp/delete-confirmation/DeleteConfirmationDialog';
import { Button } from '../../../shared/components/MUI/button/Button';
import { useAgendamentoContext, useUserContext } from '../../../shared/contexts';
import { IAgendamentoView, IUpdateAgendamento, agendamentoService } from '../../../shared/services';
import { AlertTypes, feedback } from '../../../shared/services/alertService';
import { HomeAgendamentosAgendamento } from './HomeAgendamentosAgendamento';

enum HomeTabsEnum {
  Cancelados = 0,
  Passados = 1,
  Proximos = 2
}

interface IProps {
  agendamentos?: IAgendamentoView[];
  refetch: () => any;
}

export const HomeAgendamentos: React.FC<IProps> = ({ agendamentos, refetch }) => {
  const [tabIndex, setTabIndex] = useState(HomeTabsEnum.Proximos);
  const [agendamentoId, setAgendamentoId] = useState<number>();
  const [openDialogCancelAgendamento, setOpenDialogCancelAgendamento] = useState(false);

  const navigate = useNavigate();

  const { validateEmailConfirmado } = useUserContext();

  const { setAgendamento } = useAgendamentoContext();

  const theme = useTheme();

  const colorDisabled = theme.palette.text.disabled;

  const handleClickCancel = useCallback((id?: number) => {
    id && setOpenDialogCancelAgendamento(true);
    setAgendamentoId(id);
  }, []);

  const { mutate: cancelAgendamento, isLoading: isLoadingCancelAgendamento } = useMutation((agendamento: IUpdateAgendamento) =>
    agendamentoService.update(agendamento), {
    onSuccess: () => finalizarRequisicao('Agendamento cancelado', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const finalizarRequisicao = (mensagem: string, tipoFeedback: AlertTypes) => {
    feedback(mensagem, tipoFeedback);

    if (tipoFeedback === 'success') {
      refetch();
      handleCloseDialogCancelAgendamento();
    }
  };

  const handleClickConfirmCancelAgendamento = useCallback(async () => {
    const agendamentoToCancel = agendamentos?.find(x => x.id === agendamentoId);

    if (agendamentoToCancel) {
      const submitData: IUpdateAgendamento = {
        id: agendamentoToCancel.id,
        cancelado: true,
        data: agendamentoToCancel.data,
        estabelecimentoId: agendamentoToCancel.estabelecimentoId,
        profissionalEstabelecimentoId: agendamentoToCancel.profissionalEstabelecimentoId,
        servicos: agendamentoToCancel.servicos.map(x => x.id),
        produtos: agendamentoToCancel.produtos.map(x => x.id),
        usuarioId: agendamentoToCancel.usuarioId,
        finalizado: agendamentoToCancel.finalizado
      };
      cancelAgendamento(submitData);
    }
  }, [agendamentoId]);

  const handleCloseDialogCancelAgendamento = useCallback(() => {
    setOpenDialogCancelAgendamento(false);
    setAgendamentoId(undefined);
  }, []);

  const onClickAgendar = () => {
    if (!validateEmailConfirmado()) {
      return;
    }

    navigate('/agendamento');
  };

  const onClickReagendar = (agendamento: IAgendamentoView) => {
    if (!validateEmailConfirmado()) {
      return;
    }

    setAgendamento(agendamento);
    navigate('/agendamento');
  };

  const onChangeTab = useCallback((_: any, index: number) => {
    setTabIndex(index);
  }, [tabIndex]);

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Paper variant='outlined' sx={{ borderRadius: 2, padding: 2 }}>
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography variant='h4'>Agendamentos</Typography>

          <Tabs value={tabIndex} onChange={onChangeTab}>
            <Tab label='Cancelados' />
            <Tab label='Passados' />
            <Tab label='Próximos' />
          </Tabs>

          {tabIndex === HomeTabsEnum.Cancelados &&
            <>
              {agendamentos?.filter((item) => item.cancelado).length ?
                agendamentos?.filter((item) => item.cancelado).map((item) => (
                  <HomeAgendamentosAgendamento
                    key={item.id}
                    agendamento={item}
                    disabled
                    colorDisabled={colorDisabled}
                  />
                )) : (
                  <>
                    <Typography variant='h6' color={colorDisabled}>Você ainda não tem agendamentos cancelados!</Typography>
                  </>
                )}
            </>
          }

          {tabIndex === HomeTabsEnum.Passados &&
            <>
              {agendamentos?.filter((item) => item.passado).length ? agendamentos.filter((item) => item.passado).map((item) => (
                <HomeAgendamentosAgendamento
                  key={item.id}
                  agendamento={item}
                  disabled
                  colorDisabled={colorDisabled}
                >
                  <Box display='flex' justifyContent='center' gap={2}>
                    <Button
                      label='Reagendar'
                      onClick={() => onClickReagendar(item)}
                    />
                  </Box>
                </HomeAgendamentosAgendamento>
              )) : (
                <>
                  <Typography variant='h6' color={colorDisabled}>Você ainda não tem agendamentos passados!</Typography>
                </>
              )}
            </>
          }

          {tabIndex === HomeTabsEnum.Proximos &&
            <>
              {agendamentos?.filter((item) => !item.passado && !item.cancelado).length ?
                agendamentos?.filter((item) => !item.passado && !item.cancelado).map((item) => (
                  <HomeAgendamentosAgendamento
                    key={item.id}
                    agendamento={item}
                    disabled={false}
                    colorDisabled={colorDisabled}
                  >
                    <Box>
                      <Button label='Cancelar' onClick={() => handleClickCancel(item.id)} />
                    </Box>
                  </HomeAgendamentosAgendamento>
                )) : (
                  <>
                    <Typography variant='h6' color={colorDisabled}>Você ainda não tem agendamentos!</Typography>
                  </>
                )}

              <Box>
                <Button
                  variant='contained'
                  label='Agendar'
                  onClick={onClickAgendar}
                />
              </Box>
            </>
          }
        </Box>
      </Paper>

      <DeleteConfirmationDialog
        open={openDialogCancelAgendamento}
        onClose={handleCloseDialogCancelAgendamento}
        label={'Tem certeza que deseja cancelar este agendamento?'}
        isLoading={isLoadingCancelAgendamento}
        onConfirm={handleClickConfirmCancelAgendamento}
      />
    </Box >
  );
};
