import React, { useState } from 'react';
import { Icon, List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { EstabelecimentoTabsEnum } from '../../estabelecimento/Estabelecimento';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface IProps {
  onClickAtalho: (redirect: string, page: number) => void;
  alwaysOpen: boolean;
}

export const HomeWidgetAdministradorEstabelecimento: React.FC<IProps> = ({ onClickAtalho, alwaysOpen }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Atalhos de estabelecimento" />
        {open || alwaysOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open || alwaysOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Informacoes)}>
            <ListItemIcon>
              <Icon>apartment</Icon>
            </ListItemIcon>
            <ListItemText primary="Informações" />
          </ListItemButton>

          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Servicos)}>
            <ListItemIcon>
              <Icon>building</Icon>
            </ListItemIcon>
            <ListItemText primary="Serviços" />
          </ListItemButton>

          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Produtos)}>
            <ListItemIcon>
              <Icon>shopping_cart</Icon>
            </ListItemIcon>
            <ListItemText primary="Produtos" />
          </ListItemButton>

          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Profissionais)}>
            <ListItemIcon>
              <Icon>person</Icon>
            </ListItemIcon>
            <ListItemText primary="Profissionais" />
          </ListItemButton>

          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Administradores)}>
            <ListItemIcon>
              <Icon>engineering</Icon>
            </ListItemIcon>
            <ListItemText primary="Administradores" />
          </ListItemButton>

          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Agenda)}>
            <ListItemIcon>
              <Icon>calendar_month</Icon>
            </ListItemIcon>
            <ListItemText primary="Agenda" />
          </ListItemButton>

          <ListItemButton onClick={() => onClickAtalho('/estabelecimento', EstabelecimentoTabsEnum.Financas)}>
            <ListItemIcon>
              <Icon>attach_money</Icon>
            </ListItemIcon>
            <ListItemText primary="Finanças" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};
