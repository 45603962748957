import { Icon, Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { Forbidden } from '../../shared/components/SconApp/forbidden/Forbidden';
import { TipoUsuarioEnum, useUserContext } from '../../shared/contexts';
import { LayoutBase, LayoutCadastro } from '../../shared/layouts';
import { IProfissionalView, profissionalService } from '../../shared/services';
import { AreaProfissionalAgenda, AreaProfissionalInformacoes } from './pages';
import { AreaProfissionalFinancas } from './pages/AreaProfissionalFinancas';

export enum ProfissionalTabsEnum {
  Informacoes = 0,
  Agenda = 1,
  Financas = 2
}

export interface IPropsProfissionalPagina {
  profissional: IProfissionalView,
  refetch: () => any
}

export const AreaProfissional: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(ProfissionalTabsEnum.Informacoes);
  const { usuarioId, tiposUsuario } = useUserContext();

  const { data: profissional, refetch } = useQuery(
    ['profissional'],
    () => profissionalService.getByUserId(usuarioId),
  );

  const onChangeTab = useCallback((_: any, index: number) => {
    setTabIndex(index);
  }, [tabIndex]);

  return tiposUsuario.includes(TipoUsuarioEnum.Profissional) ? (
    <LayoutBase>
      {profissional ?
        <LayoutCadastro
          header={
            <Tabs value={tabIndex} onChange={onChangeTab} variant='scrollable'>
              <Tab label='Informações' icon={<Icon>business</Icon>} iconPosition='start' />
              <Tab label='Agenda' icon={<Icon>calendar_month</Icon>} iconPosition='start' />
              <Tab label='Finanças' icon={<Icon>attach_money</Icon>} iconPosition='start' />
            </Tabs>
          }
          esconderBotaoSalvar
        >
          {tabIndex === ProfissionalTabsEnum.Informacoes && profissional && (
            <AreaProfissionalInformacoes profissional={profissional} refetch={refetch} />
          )}

          {tabIndex === ProfissionalTabsEnum.Agenda && profissional && (
            <AreaProfissionalAgenda profissional={profissional} refetch={refetch} />
          )}

          {tabIndex === ProfissionalTabsEnum.Financas && profissional && (
            <AreaProfissionalFinancas profissional={profissional} refetch={refetch} />
          )}
        </LayoutCadastro>
        :
        <>
          Cadastrar profissional
        </>
      }
    </LayoutBase>
  ) : (
    <Forbidden />
  );
};