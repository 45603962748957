import { Autocomplete, Box, LinearProgress, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendario } from '../../shared/components/SconApp/calendario/Calendario';
import { Button } from '../../shared/components/MUI/button/Button';
import { useAgendamentoContext, useUserContext } from '../../shared/contexts';
import { getDate } from '../../shared/helpers/dateFormat';
import { LayoutBase, LayoutCadastro } from '../../shared/layouts';
import { ICalendario, ICreateAgendamento, IEstabelecimentoView, IProfissionalEstabelecimentoView, IServicoView, agendamentoService, estabelecimentoService, feedback, profissionalEstabelecimentoService } from '../../shared/services';

export const Agendamento: React.FC = () => {
  const [estabelecimentoSelected, setEstabelecimentoSelected] = useState<IEstabelecimentoView>();
  const [servicoSelected, setServicoSelected] = useState<IServicoView>();
  const [profissionalSelected, setProfissionalSelected] = useState<IProfissionalEstabelecimentoView>();
  const [customer, setCustomer] = useState<string>();
  const [diaMes, setDiaMes] = useState<ICalendario>();
  const [horarioSelecionado, setHorarioSelecionado] = useState<string>();

  const { usuarioId } = useUserContext();
  const {
    agendamento,
    setAgendamento,
    viaScheduler,
    blockEstabelecimento,
    setBlockEstabelecimento,
    blockProfissional
  } = useAgendamentoContext();

  const navigate = useNavigate();

  // #region - Setando parâmetros da URL no AgendamentoContext
  const { estabelecimentoId } = useParams<{ estabelecimentoId: string }>();

  useEffect(() => {
    if (!agendamento && estabelecimentoId) {
      setAgendamento({ estabelecimentoId: parseInt(estabelecimentoId) });
      setBlockEstabelecimento(true);
    }
  }, [estabelecimentoId]);
  // #endregion

  const { data: estabelecimentos } = useQuery(
    ['estabelecimentos'],
    () => estabelecimentoService.get()
  );

  const { data: estabelecimento } = useQuery({
    queryKey: ['estabelecimento', estabelecimentoSelected],
    queryFn: () => {
      if (estabelecimentoSelected) {
        return estabelecimentoService.getById(estabelecimentoSelected.id);
      }
    },
    enabled: !!estabelecimentoSelected,
  });

  const servicos = useMemo(() => {
    let servicos = estabelecimento?.servicos;

    if (viaScheduler && !!profissionalSelected) {
      const servicosProfissionalSelected = profissionalSelected?.profissionalEstabelecimentoServicos?.map(x => x.servicoId);

      servicos = servicos?.filter(x => servicosProfissionalSelected?.includes(x.id));
    }

    return servicos || [];
  }, [estabelecimento, servicoSelected]);

  const profissionais = useMemo(() => {
    let profissionais = estabelecimento?.profissionais;

    if (!viaScheduler) {
      profissionais = profissionais?.filter(x => x.profissionalEstabelecimentoServicos?.some(y => y.servicoId == servicoSelected?.id));
    }

    return profissionais || [];
  }, [estabelecimento, servicoSelected]);

  const duracaoServico = useCallback((profissional: IProfissionalEstabelecimentoView) => {
    return profissional.profissionalEstabelecimentoServicos?.find(s => s.servicoId == servicoSelected?.id)?.duracaoServico;
  }, [servicoSelected]);

  const duracaoServicoProfissionalSelected = useMemo(() => profissionalSelected && duracaoServico(profissionalSelected), [profissionalSelected, servicoSelected]);

  // #region - Setando campos com o AgendamentoContext, caso tenha.
  useEffect(() => {
    if (agendamento) {
      estabelecimentos && setEstabelecimentoSelected(estabelecimentos.find((e) => e.id === agendamento.estabelecimentoId));

      if (estabelecimento) {
        if (servicos.length > 0 && agendamento.servicoId) {
          setServicoSelected(servicos.find((s) => s.id === agendamento.servicoId));
        }

        if (profissionais.length > 0) {
          agendamento.profissionalEstabelecimentoId && setProfissionalSelected(profissionais.find((p) => p.id === agendamento.profissionalEstabelecimentoId));
          agendamento.profissionalId && setProfissionalSelected(profissionais.find((p) => p.profissionalId === agendamento.profissionalId));
        }

        setAgendamento(undefined);
      }
    }
  }, [agendamento, estabelecimentos, estabelecimento, profissionais, servicos]);
  // #endregion

  const { data: horariosDisponiveis, remove: removeHorariosDisponiveis, isFetching: isFetchingHorariosDisponiveis } = useQuery({
    queryKey: ['horariosDisponiveis', profissionalSelected, diaMes, duracaoServicoProfissionalSelected],
    queryFn: () => {
      if (profissionalSelected && diaMes && duracaoServicoProfissionalSelected) {
        return profissionalEstabelecimentoService.getHorariosDisponiveis(
          profissionalSelected.id,
          diaMes.diaDoMes,
          diaMes.mes,
          duracaoServicoProfissionalSelected
        );
      }
    },
    enabled: !!profissionalSelected && !!diaMes && !!duracaoServicoProfissionalSelected,
  });

  const { mutate: createAgendamento, isLoading: isLoadingCreateAgendamento } = useMutation((agendamento: ICreateAgendamento) =>
    agendamentoService.create(agendamento), {
    onSuccess: () => {
      feedback('Agendamento criado', 'success');
      navigate('/home');
    },
    onError: (error: any) => feedback(String(error), 'error'),
  });

  const cleanFields = (cleanServico = false) => {
    cleanServico && setServicoSelected(undefined);
    setProfissionalSelected(undefined);
    removeHorariosDisponiveis();
  };

  const handleChangeEstabelecimento = (value?: IEstabelecimentoView) => {
    setEstabelecimentoSelected(value);
    cleanFields(true);
  };

  const handleChangeServico = (value?: IServicoView) => {
    setServicoSelected(value);
    if (!viaScheduler) {
      cleanFields();
    }
  };

  const handleChangeProfissional = (value?: IProfissionalEstabelecimentoView) => {
    if (value) {
      setProfissionalSelected(value);
      return;
    }

    cleanFields();
  };

  const handleSubmit = useCallback(() => {
    if (!diaMes || !horarioSelecionado) {
      return;
    }

    const [hora, minutos] = horarioSelecionado.split(':').map(Number);
    const date = getDate(diaMes.mes - 1, diaMes?.diaDoMes, hora, minutos);

    if (date && estabelecimentoSelected?.id && profissionalSelected?.id && servicoSelected?.id) {
      const submitData: ICreateAgendamento = {
        data: date,
        estabelecimentoId: estabelecimentoSelected.id,
        profissionalEstabelecimentoId: profissionalSelected.id,
        servicos: [servicoSelected.id],
        produtos: [],
        usuarioId: viaScheduler ? undefined : usuarioId,
        cliente: viaScheduler ? customer : undefined,
      };

      createAgendamento(submitData);
    }
  }, [estabelecimentoSelected, profissionalSelected, servicoSelected, diaMes, horarioSelecionado]);

  return (
    <LayoutBase>
      <LayoutCadastro
        header='Novo agendamento'
        onClickSalvar={handleSubmit}
        isLoading={isLoadingCreateAgendamento}
        disabled={!horarioSelecionado}
      >
        <Box display='flex' flexDirection='column' gap={2}>
          <Autocomplete
            key={estabelecimentoSelected ? 'estabelecimentoSelected' : 'estabelecimentoNotSelected'}
            disabled={blockEstabelecimento}
            options={estabelecimentos || []}
            getOptionLabel={opt => opt.nome}
            onChange={(_, value) => handleChangeEstabelecimento(value === null ? undefined : value)}
            value={estabelecimentoSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Estabelecimento'
                variant='outlined'
              />
            )}
          />

          <Autocomplete
            key={servicoSelected ? 'servicoSelected' : 'servicoNotSelected'}
            disabled={servicos.length == 0}
            options={servicos || []}
            getOptionLabel={opt => `${opt.descricao} - R$${opt.valor}`}
            onChange={(_, value) => handleChangeServico(value === null ? undefined : value)}
            value={servicoSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Serviço'
                variant='outlined'
              />
            )}
          />

          <Autocomplete
            key={profissionalSelected ? 'profissionalSelected' : 'profissionalNotSelected'}
            disabled={profissionais.length == 0 || blockProfissional}
            options={profissionais || []}
            getOptionLabel={option => {
              const nome = option.nome;
              const duracao = duracaoServico(option);
              const duracaoString = duracao ? ` - ${duracao}min` : '';
              return `${nome}${duracaoString}`;
            }}
            onChange={(_, value) => handleChangeProfissional(value === null ? undefined : value)}
            value={profissionalSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Profissional'
                variant='outlined'
              />
            )}
          />

          {viaScheduler &&
            <TextField
              label='Cliente'
              value={customer}
              required
              onChange={(e) => setCustomer(e.target.value)}
            />
          }

          <Calendario diaMes={diaMes} setDiaMes={setDiaMes} />

          {horariosDisponiveis && horariosDisponiveis.length > 0 ? (
            <>
              <Typography variant='h6'>Horários disponíveis:</Typography>

              <Box display='flex' alignItems='center' gap={2} flexWrap='wrap'>
                {horariosDisponiveis.map((item) =>
                  <Button
                    key={item}
                    label={item}
                    onClick={() => setHorarioSelecionado(item)}
                    variant={item === horarioSelecionado ? 'contained' : 'outlined'}
                    color='primary'
                    size='large'
                    borderRounded={false}
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              {isFetchingHorariosDisponiveis ? (
                <>
                  <Typography variant='h6'>Horários disponíveis:</Typography>
                  <LinearProgress />
                </>
              ) : (
                <Typography variant='h6'>Não há horários disponíveis para este dia...</Typography>
              )}
            </>
          )}
        </Box>
      </LayoutCadastro>
    </LayoutBase>
  );
};
