import { Box, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Button } from '../components/MUI/button/Button';

type Props = {
  children?: React.ReactNode;
  heightDefault?: number;
  header?: string | React.ReactNode;
  esconderBotaoSalvar?: boolean;
  labelBotaoSalvar?: string;
  isLoading?: boolean;
  disabled?: boolean;
  isSubmit?: boolean;
  onClickSalvar?: () => void;
};

export const LayoutCadastro: React.FC<Props> = ({ children, header, esconderBotaoSalvar, labelBotaoSalvar, isLoading, disabled, isSubmit, onClickSalvar }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container component={Paper} variant='outlined' maxWidth='lg'>
      {header &&
        <>
          <Grid item xs={12}>
            {typeof (header) === 'string' &&
              <Box display='flex' flex={1} justifyContent='center' padding={3}>
                <Typography variant={mdDown ? 'h6' : 'h5'}>{header}</Typography>
              </Box>
            }

            {typeof (header) === 'object' &&
              <>
                {header}
              </>
            }
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      }

      <Grid item xs={12} >
        <Box padding={3}>
          {children}
        </Box>
      </Grid>

      {!esconderBotaoSalvar &&
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} display='flex' justifyContent='end' height={68.5} padding={2}>
            <Button
              variant='contained'
              label={labelBotaoSalvar ?? 'Salvar'}
              minWidth={200}
              onClick={onClickSalvar}
              isLoading={isLoading}
              disabled={isLoading || disabled}
              type={isSubmit ? 'submit' : 'button'}
            />
          </Grid>
        </Grid>
      }
    </Grid>
  );
};