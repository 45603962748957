import { createContext, useContext, useState } from 'react';

interface IEstabelecimentoContextData {
  page: number;
  setPage: (page: number) => void;
}

const EstabelecimentoContext = createContext({} as IEstabelecimentoContextData);

export const useEstabelecimentoContext = () => {
  return useContext(EstabelecimentoContext);
};

type Props = {
  children?: React.ReactNode,
};

export const EstabelecimentoProvider: React.FC<Props> = ({ children }) => {
  const [page, setPage] = useState(0);

  return (
    <EstabelecimentoContext.Provider value={{ page, setPage }}>
      {children}
    </EstabelecimentoContext.Provider>
  );
};
