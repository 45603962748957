import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home, Perfil, Agendamento, Estabelecimento, CriarEstabelecimento, ConfirmEmail, CreateProfessional, AreaProfissional } from '../pages';
import { TipoUsuarioEnum, useMenuContext, useUserContext } from '../shared/contexts';
import { Forbidden } from '../shared/components/SconApp/forbidden/Forbidden';

export const AppRoutes: React.FC = () => {
  const { setMenuOptions } = useMenuContext();
  const { tiposUsuario } = useUserContext();

  useEffect(() => {
    const menuOptions = [
      {
        icon: 'person',
        label: 'Perfil',
        path: '/perfil'
      }
    ];

    if (tiposUsuario.includes(TipoUsuarioEnum.Profissional)) {
      menuOptions.push(
        {
          icon: 'engineering',
          label: 'Área do profissional',
          path: '/profissional'
        }
      );
    }

    if (tiposUsuario.includes(TipoUsuarioEnum.AdministradorEstabelecimento)) {
      menuOptions.push(
        {
          icon: 'apartment',
          label: 'Meu estabelecimento',
          path: '/estabelecimento'
        }
      );
    }

    if (tiposUsuario.includes(TipoUsuarioEnum.AdministradorSistema)) {
      menuOptions.push(
        {
          icon: 'person',
          label: 'Criar estabelecimento',
          path: '/criar-estabelecimento'
        }
      );
    }

    setMenuOptions(menuOptions);
  }, []);

  return (
    <Routes>
      <Route path="/agendamento" element={<Agendamento />} />
      <Route path="/agendamento/:estabelecimentoId" element={<Agendamento />} />
      <Route path="/confirm-email/:email/:token" element={<ConfirmEmail />} />
      <Route path="/estabelecimento" element={<Estabelecimento />} />
      <Route path="/profissional" element={<AreaProfissional />} />
      <Route path="/criar-estabelecimento" element={<CriarEstabelecimento />} />
      <Route path="/home" element={<Home />} />
      <Route path="/convidar-profissional/:estabelecimentoId" element={<CreateProfessional />} />
      <Route path="/perfil" element={<Perfil />} />
      <Route path="/sem-permissao" element={<Forbidden />} />

      <Route path="*" element={<Navigate to="/home" />}></Route>
    </Routes>
  );
};