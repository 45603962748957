import { Box, Dialog } from '@mui/material';
import { useCallback, useState } from 'react';
import { Listagem } from '../../../shared/components/SconApp/listagem/Listagem';
import { IPropsEstabelecimentoPagina } from '../Estabelecimento';
import { Button } from '../../../shared/components/MUI/button/Button';
import { feedback } from '../../../shared/services/alertService';
import { EstabelecimentoServicosCadastro } from './EstabelecimentoServicosCadastro';
import { DeleteConfirmationDialog } from '../../../shared/components/SconApp/delete-confirmation/DeleteConfirmationDialog';
import { servicoService } from '../../../shared/services';
import { useMutation } from '@tanstack/react-query';

export const EstabelecimentoServicos: React.FC<IPropsEstabelecimentoPagina> = ({ estabelecimento, refetch }) => {
  const [openDialogCadastro, setOpenDialogCadastro] = useState(false);
  const [openDialogDeletar, setOpenDialogDeletar] = useState(false);
  const [id, setId] = useState<number>();

  const { mutate: remove, isLoading: isLoadingDelete } = useMutation((id: number) =>
    servicoService.remove(id), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => feedback(String(error), 'error'),
  });

  const onClickEdit = useCallback((id: number) => {
    setOpenDialogCadastro(true);
    setId(id);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDialogCadastro(false);
    setOpenDialogDeletar(false);
    setId(undefined);
  }, []);

  const onClickDelete = useCallback((id: number) => {
    setOpenDialogDeletar(true);
    setId(id);
  }, []);

  const onClickConfirmDelete = useCallback(async () => {
    id && remove(id);
  }, [id]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={3}
      padding={3}
    >
      <Box display='flex' flex={1} justifyContent='end'>
        <Button
          label='Novo'
          variant='contained'
          size='large'
          onClick={() => setOpenDialogCadastro(true)}
        />
      </Box>

      <Listagem
        lista={estabelecimento.servicos!.map((item) => ({ id: item.id || 0, descricao: `${item.descricao} - R$${item.valor}` }))}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />

      <Dialog
        open={openDialogCadastro}
        onClose={handleClose}
      >
        {estabelecimento.id &&
          <EstabelecimentoServicosCadastro
            servicoId={id}
            estabelecimentoId={estabelecimento.id}
            refetch={refetch}
            onCloseEdit={handleClose}
          />
        }
      </Dialog>

      <DeleteConfirmationDialog
        onClose={() => setOpenDialogDeletar(false)}
        onConfirm={onClickConfirmDelete}
        open={openDialogDeletar}
        isLoading={isLoadingDelete}
        label='Tem certeza que deseja deletar este serviço?'
      />
    </Box>
  );
};