import { Dialog, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { dateFormat } from '../../../helpers/dateFormat';
import { IAgendamentoView } from '../../../services';
import { Listagem } from '../listagem/Listagem';
import { FinanceiroAgendamentoCadastro } from './components/FinanceiroAgendamentoCadastro';

interface IProps {
  agendamentos: IAgendamentoView[],
  refetch: () => void,
  finalizados: boolean
}

export const FinanceiroAgendamento: React.FC<IProps> = ({ agendamentos, refetch, finalizados }) => {
  const [openDialogCadastro, setOpenDialogCadastro] = useState(false);
  const [id, setId] = useState<number>();

  const theme = useTheme();

  const onClickEdit = useCallback((id: number) => {
    setOpenDialogCadastro(true);
    setId(id);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDialogCadastro(false);
    setId(undefined);
  }, []);

  return agendamentos && (
    <>
      <Listagem
        lista={agendamentos.map((agendamento) => (
          {
            id: agendamento.id,
            descricao: `${dateFormat(agendamento.data)} ${dateFormat(agendamento.data, 'LT')} - ${agendamento.nomeProfissional} - R$${agendamento.valorTotal}`
          }
        ))}
        onClickEdit={onClickEdit}
        editIcon={finalizados ? 'visibility' : undefined}
        editOnClick
        bgColor={finalizados ? undefined : theme.palette.primary.main}
        bgColorOnHover={finalizados ? undefined : theme.palette.primary.dark}
        hover
      />

      {id &&
        <Dialog
          open={openDialogCadastro}
          onClose={handleClose}
        >
          <FinanceiroAgendamentoCadastro
            agendamentoId={id}
            refetch={refetch}
            onCloseEdit={handleClose}
          />
        </Dialog>
      }
    </>
  );
};