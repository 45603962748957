import { Visibility } from '@mui/icons-material';
import {
  Alert,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dispatch, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { VTextField } from '../../../../forms';
import getValidationErrors from '../../../../helpers/getValidationErrors';
import { ILogin } from '../../../../services/api/authService';
import { Button } from '../../../MUI/button/Button';
import { IFormData } from '../LoginPage';

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  senha: Yup.string().required().min(5),
});

interface IProps {
  mensagemErro: string | undefined;
  setMensagemErro: Dispatch<React.SetStateAction<string | undefined>>;
  mostrarSenha: boolean | undefined;
  setMostrarSenha: Dispatch<React.SetStateAction<boolean>>;
  mutateLogin: UseMutateFunction<string | void, any, ILogin, unknown>;
  isLoadingLogin: boolean;
}

export const Login: React.FC<IProps> = ({
  mensagemErro,
  mostrarSenha,
  setMostrarSenha,
  mutateLogin,
  isLoadingLogin,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});

      await loginSchema.validate(
        { email: data.email, senha: data.senha },
        { abortEarly: false }
      );

      mutateLogin({
        email: data.email,
        senha: data.senha,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id='login-form'>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant='h5' align='center'>
          Login
        </Typography>

        <Divider variant='middle' />

        {mensagemErro && <Alert severity='error'>{mensagemErro}</Alert>}

        <VTextField
          fullWidth
          label='E-mail'
          name='email'
          type='email'
          required
          disabled={isLoadingLogin}
        />

        <VTextField
          fullWidth
          label='Senha'
          name='senha'
          type={mostrarSenha ? 'text' : 'password'}
          required
          disabled={isLoadingLogin}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  tabIndex={-1}
                  onClick={() => setMostrarSenha(!mostrarSenha)}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box display='flex' justifyContent='left'>
          <Button
            label='Esqueceu sua senha?'
            variant='text'
            onClick={() => undefined}
            style={{ fontSize: 12 }}
          />
        </Box>
      </Box>
    </Form>
  );
};
