import { Box, Container } from '@mui/material';
import { BarraSuperior } from '../components/SconApp/barra-superior/BarraSuperior';
import { AlertComponent } from '../components/MUI/alert/AlertComponent';

type Props = {
  children?: React.ReactNode;
  barraDeFerramentas?: React.ReactNode;
};

export const LayoutBase: React.FC<Props> = ({ children, barraDeFerramentas }) => {
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <BarraSuperior />

      <Container sx={{ height: '100vh', padding: 3, maxWidth: 'lg', overflow: 'auto' }}>
        {barraDeFerramentas &&
          <Box marginBottom={2}>
            {barraDeFerramentas}
          </Box>
        }

        {children}
      </Container>

      <AlertComponent />
    </Box>
  );
};