import { IconButton, Icon, Box, Typography } from '@mui/material';
import { ICalendario, calendarioService } from '../../../services/api/calendarioService';
import { Dispatch, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

interface IProps {
  diaMes: ICalendario | undefined;
  setDiaMes: Dispatch<React.SetStateAction<ICalendario | undefined>>;
}

export const Calendario: React.FC<IProps> = ({ diaMes, setDiaMes }) => {

  const carousel = useRef<HTMLElement>(null);

  const { data: dias } = useQuery(['dias'], () =>
    calendarioService.obterDias(), {
    onSuccess: (data) => {
      setDiaMes(data[0]);
    }
  }
  );

  const handleLeftClick = () => {
    if (carousel.current) {
      carousel.current.scrollLeft -= 300;
    }
  };

  const handleRightClick = () => {
    if (carousel.current) {
      carousel.current.scrollLeft += 300;
    }
  };

  return (
    <Box display='flex' alignItems='center' gap={2}>
      <IconButton onClick={handleLeftClick}>
        <Icon>arrow_back</Icon>
      </IconButton>

      <Box
        display='flex'
        alignItems='center'
        overflow='hidden'
        sx={{ scrollBehavior: 'smooth' }}
        gap={1}
        ref={carousel}
      >
        {dias && dias.map((dia) =>
          <Box
            key={dia.id}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            component='span'
            onClick={() => setDiaMes(dia)}
            sx={{
              backgroundColor: dia === diaMes ? '#c2185b' : 'none',
              cursor: 'pointer'
            }}
            padding={1}
            borderRadius={1}
            draggable
          >
            <Typography color={dia === diaMes ? 'secondary' : 'primary'}>{dia.diaDaSemana}</Typography>

            <Box display='flex' alignItems='center'>
              <Typography color={dia === diaMes ? 'secondary' : 'primary'} fontSize={16} fontWeight='bold'>{dia.diaDoMesString}</Typography>
              <Typography color={dia === diaMes ? 'secondary' : 'primary'} fontSize={12}>/{dia.mesString}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      <IconButton onClick={handleRightClick}>
        <Icon>arrow_forward</Icon>
      </IconButton>
    </Box>
  );
};