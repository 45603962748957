import { Api } from './axios-config';

export interface IProdutoView {
  id: number;
  estabelecimentoId: number;
  descricao: string;
  valor: number;
  foto?: string;
}

const getById = async (id: number): Promise<IProdutoView> => {
  try {
    const { data } = await Api.get<IProdutoView>(
      `/produto/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter produto.');
  }
};


const getByIds = async (ids: number[]): Promise<IProdutoView[]> => {
  try {
    const { data } = await Api.post<IProdutoView[]>(
      '/produto/ids', ids
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter produtos.');
  }
};

const getByEstabelecimento = async (estabelecimentoId: number): Promise<IProdutoView[]> => {
  try {
    const { data } = await Api.get<IProdutoView[]>(
      `/produto/estabelecimento/${estabelecimentoId}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter produto.');
  }
};

export interface ICreateProduto {
  estabelecimentoId: number;
  descricao: string;
  valor: number;
}

const create = async (body: ICreateProduto): Promise<number> => {
  try {
    const { data } = await Api.post(
      '/produto',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export interface IUpdateProduto {
  id: number;
  estabelecimentoId: number;
  descricao: string;
  valor: number;
}

const update = async (body: IUpdateProduto) => {
  try {
    const { data } = await Api.put(
      '/produto',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: number) => {
  try {
    await Api.delete(
      `/produto/${id}`
    );

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export const produtoService = {
  getById,
  getByIds,
  getByEstabelecimento,
  create,
  update,
  remove
};