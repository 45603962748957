import moment from 'moment';
import 'moment/locale/pt-br';

export const dateFormat = (date: Date, format = 'L') => {
  moment.locale('pt-br');

  return date ? moment.utc(date).format(format) : '';
};

export const getDate = (month: number, day: number, hours: number, minutes: number) => {
  const date = new Date();
  date.setDate(day);
  date.setMonth(month);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};