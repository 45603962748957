import { Autocomplete, Box, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { dateFormat } from '../../../../helpers/dateFormat';
import { LayoutCadastro } from '../../../../layouts';
import { finalizarRequisicao } from '../../../../services';
import { IAgendamentoView, IUpdateAgendamento, agendamentoService } from '../../../../services/api/agendamentoService';
import { Button } from '../../../MUI/button/Button';
import { DeleteConfirmationDialog } from '../../delete-confirmation/DeleteConfirmationDialog';

interface IEventDetailsProps {
  agendamento: IAgendamentoView;
  refetch: () => any;
  onClose: () => any;
}

export const EventDetails: React.FC<IEventDetailsProps> = ({ agendamento, refetch, onClose }) => {
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const { mutate: cancel, isLoading: isLoadingCancel } = useMutation((agendamento: IUpdateAgendamento) =>
    agendamentoService.update(agendamento), {
    onSuccess: () => finalizarRequisicao('Agendamento cancelado', 'success', () => {
      refetch();
      setOpenDialogCancel(false);
      onClose();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const handleConfirmCancel = useCallback(async () => {
    const submitData: IUpdateAgendamento = {
      id: agendamento.id,
      cancelado: true,
      data: agendamento.data,
      estabelecimentoId: agendamento.estabelecimentoId,
      profissionalEstabelecimentoId: agendamento.profissionalEstabelecimentoId,
      servicos: agendamento.servicos.map(x => x.id),
      produtos: agendamento.produtos.map(x => x.id),
      usuarioId: agendamento.usuarioId,
      finalizado: agendamento.finalizado
    };

    cancel(submitData);
  }, []);

  return (
    <LayoutCadastro header='Editar agendamento' isLoading={false} esconderBotaoSalvar>
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField
          label='Cliente'
          value={agendamento.nomeCliente}
          disabled
        />

        <Autocomplete
          multiple
          getOptionLabel={opt => `${opt.descricao} - R$${opt.valor}`}
          options={agendamento.servicos}
          value={agendamento.servicos}
          disabled
          renderInput={(params) => (
            <TextField
              {...params}
              label='Serviços'
              variant='outlined'
            />
          )}
        />

        <TextField
          label='Data'
          value={dateFormat(agendamento.data, 'LLL')}
          disabled
        />

        {!agendamento.passado &&
          <Button label='Cancelar' onClick={() => setOpenDialogCancel(true)} />
        }
      </Box>

      <DeleteConfirmationDialog
        isLoading={isLoadingCancel}
        onClose={() => setOpenDialogCancel(false)}
        onConfirm={handleConfirmCancel}
        open={openDialogCancel}
        label='Tem certeza que deseja cancelar este agendamento?'
      />
    </LayoutCadastro>
  );
};