import { Autocomplete, Box, Dialog, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { LayoutCadastro } from '../../../layouts';
import { IProfissionalEstabelecimentoServicoView, IServicoView, feedback } from '../../../services';
import { Button } from '../../MUI/button/Button';
import { Listagem } from '../listagem/Listagem';

interface IProps {
  profissionalEstabelecimentoId?: number;
  estabelecimentoId: number;
  servicos?: IServicoView[];
  profissionalServicos: {
    [key: number]: IProfissionalEstabelecimentoServicoView[];
  };
  setProfissionalServicos: React.Dispatch<React.SetStateAction<{
    [key: number]: IProfissionalEstabelecimentoServicoView[];
  }>>;
}

export const ProfissionalEstabelecimentoServico: React.FC<IProps> = ({ profissionalEstabelecimentoId, estabelecimentoId, servicos, profissionalServicos, setProfissionalServicos }) => {
  const [isEditServico, setIsEditServico] = useState(false);
  const [openDialogServico, setOpenDialogServico] = useState(false);
  const [duracaoServico, setDuracaoServico] = useState<number>();
  const [servicoProfissionalSelecionado, setServicoProfissionalSelecionado] = useState<IServicoView>();

  const onClickSalvarServico = useCallback(() => {
    if (servicoProfissionalSelecionado?.id && duracaoServico) {
      const profissionalServico: IProfissionalEstabelecimentoServicoView = {
        id: 0,
        profissionalEstabelecimentoId: profissionalEstabelecimentoId || 0,
        servicoId: servicoProfissionalSelecionado.id,
        duracaoServico: duracaoServico,
        servico: servicoProfissionalSelecionado
      };

      if (isEditServico) {
        setProfissionalServicos((previous) => ({
          ...previous,
          [estabelecimentoId]: previous[estabelecimentoId].map((item) =>
            item.servicoId === profissionalServico.servicoId ? profissionalServico : item
          ),
        }));
      } else {
        const servicoExistente = profissionalServicos[estabelecimentoId].some(item => item.servicoId == servicoProfissionalSelecionado.id);

        if (servicoExistente) {
          feedback('Serviço já selecionado para o profissional.', 'error');
          return;
        }

        setProfissionalServicos(previous => ({
          ...previous,
          [estabelecimentoId]: [...(previous[estabelecimentoId] || []), profissionalServico]
        }));
      }

      handleClose();
    } else {
      feedback('Selecione um serviço para salvar.', 'error');
    }
  }, [servicoProfissionalSelecionado, duracaoServico, profissionalServicos]);

  const onClickDeleteServico = useCallback((id: number) => {
    setProfissionalServicos(previous => ({
      ...previous,
      [estabelecimentoId]: [...(previous[estabelecimentoId].filter(item => item.servicoId !== id) || [])]
    }));
  }, []);

  const onClickEditServico = useCallback((id: number) => {
    const servico = servicos?.find(item => item.id === id);
    const profissionalServico = profissionalServicos[estabelecimentoId].find(item => item.servicoId === id);

    setIsEditServico(true);
    setServicoProfissionalSelecionado(servico);
    setDuracaoServico(profissionalServico?.duracaoServico);
    setOpenDialogServico(true);
  }, [profissionalServicos]);

  const handleClose = useCallback(() => {
    setIsEditServico(false);
    setServicoProfissionalSelecionado(undefined);
    setDuracaoServico(undefined);
    setOpenDialogServico(false);
  }, []);

  const duracoesServico = [15, 30, 45, 60];

  return (
    <Box>
      <Box display='flex' justifyContent='center'>
        <Button
          label='+ SERVICO'
          borderRounded={false}
          onClick={() => setOpenDialogServico(true)}
        />
      </Box>

      <Listagem
        lista={profissionalServicos[estabelecimentoId].map((item) => ({ id: item.servicoId, descricao: item.servico?.descricao + ' - ' + item.duracaoServico + 'min' }))}
        onClickEdit={onClickEditServico}
        onClickDelete={onClickDeleteServico}
      />

      <Dialog
        open={openDialogServico}
        onClose={handleClose}
        transitionDuration={0}
      >
        <LayoutCadastro header='Novo serviço para o profissional' heightDefault={200} onClickSalvar={onClickSalvarServico}>
          <Box display='flex' flexDirection='column' gap={2}>
            <Autocomplete
              options={servicos || []}
              getOptionLabel={(opt) => opt.descricao}
              value={servicoProfissionalSelecionado}
              disabled={isEditServico}
              onChange={(_, servico) => {
                if (servico) {
                  setServicoProfissionalSelecionado(servico);
                }
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Serviço'
                  variant='outlined'
                />
              )}
            />

            <Autocomplete
              disabled={!servicos}
              options={duracoesServico}
              getOptionLabel={(opt) => opt.toString()}
              onChange={(_, value) => setDuracaoServico(value || undefined)}
              value={duracaoServico}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Duração do serviço (min)'
                  variant='outlined'
                />
              )}
            />
          </Box>
        </LayoutCadastro>
      </Dialog>
    </Box>
  );
};