import { Dispatch, createContext, useContext, useState } from 'react';

interface IAgendamentoContextObject {
  estabelecimentoId: number;
  profissionalEstabelecimentoId?: number;
  profissionalId?: number;
  servicoId?: number;
}

interface IAgendamentoContextData {
  agendamento?: IAgendamentoContextObject;
  setAgendamento: Dispatch<React.SetStateAction<IAgendamentoContextObject | undefined>>;
  viaScheduler: boolean;
  setViaScheduler: Dispatch<React.SetStateAction<boolean>>;
  blockEstabelecimento: boolean;
  setBlockEstabelecimento: Dispatch<React.SetStateAction<boolean>>;
  blockProfissional: boolean;
  setBlockProfissional: Dispatch<React.SetStateAction<boolean>>;
}

const AgendamentoContext = createContext({} as IAgendamentoContextData);

export const useAgendamentoContext = () => {
  return useContext(AgendamentoContext);
};

type Props = {
  children?: React.ReactNode,
};

export const AgendamentoProvider: React.FC<Props> = ({ children }) => {
  const [agendamento, setAgendamento] = useState<IAgendamentoContextObject>();
  const [viaScheduler, setViaScheduler] = useState(false);
  const [blockEstabelecimento, setBlockEstabelecimento] = useState(false);
  const [blockProfissional, setBlockProfissional] = useState(false);

  return (
    <AgendamentoContext.Provider value={{
      agendamento,
      setAgendamento,
      viaScheduler,
      setViaScheduler,
      blockEstabelecimento,
      setBlockEstabelecimento,
      blockProfissional,
      setBlockProfissional
    }}>
      {children}
    </AgendamentoContext.Provider>
  );
};
