import { Api } from './axios-config';
import { IProdutoView } from './produtoService';
import { IServicoView } from './servicoService';

export interface IAgendamentoView {
  id: number;
  data: Date;
  horaInicio: string;
  horaFim: string;
  usuarioId?: number;
  servicos: IServicoView[];
  produtos: IProdutoView[];
  profissionalEstabelecimentoId: number;
  estabelecimentoId: number;
  cancelado: boolean;
  passado: boolean;
  finalizado: boolean;
  valorTotal: number;
  corProfissional: string;
  nomeProfissional: string;
  nomeCliente: string;
  nomeEstabelecimento: string;
  enderecoEstabelecimento: string;
}

const getById = async (id: number): Promise<IAgendamentoView> => {
  try {
    const { data } = await Api.get<IAgendamentoView>(
      `/agendamento/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter agendamento.');
  }
};

const get = async (
  estabelecimentoId?: number,
  profissionalEstabelecimentoId?: number,
  profissionalId?: number,
  dataInicio?: Date,
  dataFim?: Date
): Promise<IAgendamentoView[]> => {
  try {
    const queryParams = {
      estabelecimentoId: estabelecimentoId?.toString(),
      profissionalEstabelecimentoId: profissionalEstabelecimentoId?.toString(),
      profissionalId: profissionalId?.toString(),
      dataInicio: dataInicio?.toISOString() || '',
      dataFim: dataFim?.toISOString() || '',
    };

    const { data } = await Api.get('/agendamento', {
      params: queryParams,
    });

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter agendamento.');
  }
};

export interface ICreateAgendamento {
  data: Date;
  usuarioId?: number;
  cliente?: string;
  servicos: number[];
  produtos: number[];
  profissionalEstabelecimentoId: number;
  estabelecimentoId: number;
}

const create = async (body: ICreateAgendamento): Promise<number> => {
  try {
    const { data } = await Api.post(
      '/agendamento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export interface IUpdateAgendamento {
  id: number;
  data: Date;
  cancelado: boolean;
  finalizado: boolean;
  usuarioId?: number;
  servicos: number[];
  produtos: number[];
  profissionalEstabelecimentoId: number;
  estabelecimentoId: number;
}

const update = async (body: IUpdateAgendamento) => {
  try {
    const { data } = await Api.put(
      '/agendamento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: number) => {
  try {
    await Api.delete(
      `/agendamento/${id}`
    );

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export const agendamentoService = {
  getById,
  get,
  create,
  update,
  remove
};