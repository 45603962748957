import { Visibility } from '@mui/icons-material';
import {
  Alert,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dispatch, useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { VTextField, VTextFieldCPF, VTextFieldPhone } from '../../../../forms';
import getValidationErrors from '../../../../helpers/getValidationErrors';
import { ICreateUsuario } from '../../../../services';
import { IFormData } from '../LoginPage';

const createSchema: Yup.Schema<IFormData> = Yup.object().shape({
  nome: Yup.string().required().min(5),
  email: Yup.string().email().required(),
  senha: Yup.string().required().min(5),
  confirmacaoDeSenha: Yup.string()
    .required()
    .min(5)
    .oneOf([Yup.ref('senha')], 'A confirmação de senha deve ser igual a senha'),
});

interface IProps {
  mensagemErro: string | undefined;
  mostrarSenha: boolean | undefined;
  setMostrarSenha: Dispatch<React.SetStateAction<boolean>>;
  mutateCreate: UseMutateFunction<string | void, any, ICreateUsuario, unknown>;
  isLoadingCreate: boolean;
}

export const Cadastro: React.FC<IProps> = ({
  mensagemErro,
  mostrarSenha,
  setMostrarSenha,
  mutateCreate,
  isLoadingCreate,
}) => {
  const [mostrarSenhaConfirmacao, setMostrarSenhaConfirmacao] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});

      await createSchema.validate(data, { abortEarly: false });

      mutateCreate({
        nome: data.nome,
        email: data.email,
        cpf: data.cpf,
        telefone: data.telefone,
        senha: data.senha,
        confirmacaoDeSenha: data.confirmacaoDeSenha,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id='cadastro-form'>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant='h5' align='center'>
          Faça o seu cadastro
        </Typography>

        <Divider variant='middle' />

        {mensagemErro && <Alert severity='error'>{mensagemErro}</Alert>}

        <VTextField
          fullWidth
          label='Nome completo'
          name='nome'
          required
          disabled={isLoadingCreate}
        />

        <Box display='flex' gap={2}>
          <VTextFieldCPF name='cpf' label='CPF' disabled={isLoadingCreate} />

          <VTextFieldPhone
            name='telefone'
            label='Telefone'
            disabled={isLoadingCreate}
          />
        </Box>

        <VTextField
          fullWidth
          label='E-mail'
          name='email'
          type='email'
          required
          disabled={isLoadingCreate}
        />

        <Box display='flex' gap={2}>
          <VTextField
            fullWidth
            label='Senha'
            name='senha'
            type={mostrarSenha ? 'text' : 'password'}
            required
            disabled={isLoadingCreate}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setMostrarSenha(!mostrarSenha)}
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <VTextField
            fullWidth
            label='Confrme sua senha'
            name='confirmacaoDeSenha'
            type={mostrarSenhaConfirmacao ? 'text' : 'password'}
            required
            disabled={isLoadingCreate}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    tabIndex={-1}
                    onClick={() =>
                      setMostrarSenhaConfirmacao(!mostrarSenhaConfirmacao)
                    }
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Form>
  );
};
