import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dayjs } from 'dayjs';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Forbidden } from '../../shared/components/SconApp/forbidden/Forbidden';
import { TipoUsuarioEnum, useUserContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';
import { VTextField, VTextFieldCNPJ } from '../../shared/forms';
import getValidationErrors from '../../shared/helpers/getValidationErrors';
import { LayoutBase, LayoutCadastro } from '../../shared/layouts';
import { ICreateEstabelecimento, ICreateAdministradorEstabelecimento, IUsuarioView, administradorEstabelecimentoService, usuarioService, estabelecimentoService } from '../../shared/services';
import { feedback } from '../../shared/services/alertService';

const schema = Yup.object().shape({
  nome: Yup.string().required(),
  endereco: Yup.string().required(),
  cnpj: Yup.string()
});

export const CriarEstabelecimento: React.FC = () => {
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<IUsuarioView>();
  const [horaInicio, setHoraInicio] = useState<Dayjs>();
  const [horaFim, setHoraFim] = useState<Dayjs>();

  const formRef = useRef<FormHandles>(null);

  const { tiposUsuario } = useUserContext();
  const navigate = useNavigate();

  const { data: usuarios } = useQuery(['usuarios'], () =>
    usuarioService.get(true),
  );

  const { mutate: createAdministradorEstabelecimento, isLoading: isLoadingCreateAdministradorEstabelecimento } = useMutation((administradorEstabelecimento: ICreateAdministradorEstabelecimento) =>
    administradorEstabelecimentoService.create(administradorEstabelecimento), {
    onSuccess: () => {
      feedback('Usuário gerado!', 'success');
      navigate('/home');
    },
    onError: (error: any) => {
      feedback(String(error), 'error');
    },
  });

  const { mutate: createEstabelecimento, isLoading: isLoadingCreateEstabelecimento, data: estabelecimentoId } = useMutation((estabelecimento: ICreateEstabelecimento) =>
    estabelecimentoService.create(estabelecimento), {
    onError: (error: any) => {
      feedback(String(error), 'error');
    },
  });

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate({ nome: data.nome, endereco: data.endereco, cnpj: data.cnpj }, { abortEarly: false });

      if (!horaInicio || !horaFim) {
        feedback('Os campos horário de início e horário de término são obrigatórios!', 'error');
        return;
      }

      if (usuarioSelecionado) {
        const estabelecimento: ICreateEstabelecimento = {
          nome: data.nome,
          endereco: data.endereco,
          horaInicio: horaInicio.format('HH:mm'),
          horaFim: horaFim.format('HH:mm'),
          cnpj: data.cnpj
        };

        createEstabelecimento(estabelecimento);

        if (estabelecimentoId) {
          const administradorEstabelecimento: ICreateAdministradorEstabelecimento = {
            usuarioId: usuarioSelecionado.id,
            estabelecimentoId: estabelecimentoId,
          };

          createAdministradorEstabelecimento(administradorEstabelecimento);
        }

      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, [usuarioSelecionado, horaInicio, horaFim]);

  return tiposUsuario.includes(TipoUsuarioEnum.AdministradorSistema) ? (
    <LayoutBase>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <LayoutCadastro
          isLoading={isLoadingCreateAdministradorEstabelecimento || isLoadingCreateEstabelecimento}
          labelBotaoSalvar='Criar estabelecimento'
          isSubmit
        >
          <Box
            display='flex'
            flexDirection='column'
            gap={3}
            padding={3}
          >
            <Typography><strong>Dados do administrador do estabelecimento:</strong></Typography>

            <Autocomplete
              options={usuarios || []}
              getOptionLabel={(opt) => opt.nome + ' (' + opt.email + ')'}
              noOptionsText={Environment.listagemVazia}
              onChange={(_, value) => value && setUsuarioSelecionado(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Administrador do estabelecimento'
                  variant='outlined'
                  placeholder={Environment.inputDeBusca}
                />
              )}
            />

            <Typography><strong>Dados do estabelecimento:</strong></Typography>

            <VTextField name='nome' label='Nome' />

            <VTextFieldCNPJ name='cnpj' label='CNPJ' />

            <VTextField name='endereco' label='Endereco' />

            <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
              <TimePicker
                label='Horário de início'
                sx={{ width: '100%' }}
                ampm={false}
                value={horaInicio}
                onChange={(value: any) => setHoraInicio(value)}
              />

              <TimePicker
                label='Horário de termino'
                sx={{ width: '100%' }}
                ampm={false}
                value={horaFim}
                onChange={(value: any) => setHoraFim(value)}
              />
            </Box>
          </Box>
        </LayoutCadastro>
      </Form>
    </LayoutBase>
  ) : (
    <Forbidden />
  );
};
