import { Api } from './axios-config';
import { IUsuarioView } from './usuarioService';

export interface ILogin {
  email: string,
  senha: string,
}

const login = async (body: ILogin): Promise<IUsuarioView | Error> => {
  try {
    const { data } = await Api.post<IUsuarioView | Error>(
      '/auth/login',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const googleLogin = async (jwt: string): Promise<IUsuarioView | Error> => {
  try {
    const { data } = await Api.post<IUsuarioView | Error>(
      `/auth/google-login?jwt=${jwt}`
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const logout = async () => {
  try {
    await Api.post('/auth/logout');

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export interface ICreateUsuario {
  nome: string,
  email: string,
  senha: string,
  confirmacaoDeSenha: string,
  telefone?: number,
  cpf?: string
}

const create = async (body: ICreateUsuario): Promise<IUsuarioView | Error> => {
  try {
    const { data } = await Api.post<IUsuarioView | Error>(
      '/auth/create',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const getUserFromCookie = async (): Promise<IUsuarioView> => {
  try {
    const { data } = await Api.get<IUsuarioView>(
      '/auth'
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter usuário.');
  }
};

export const authService = {
  login,
  googleLogin,
  logout,
  create,
  getUserFromCookie
};