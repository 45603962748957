import { Avatar, Badge, Box, Dialog, Popover, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { SconIcon } from '../../../shared/assets';
import { DeleteConfirmationDialog } from '../../../shared/components/SconApp/delete-confirmation/DeleteConfirmationDialog';
import { Listagem } from '../../../shared/components/SconApp/listagem/Listagem';
import { Button } from '../../../shared/components/MUI/button/Button';
import { useUserContext } from '../../../shared/contexts';
import { profissionalEstabelecimentoService } from '../../../shared/services';
import { feedback } from '../../../shared/services/alertService';
import { IPropsEstabelecimentoPagina } from '../Estabelecimento';
import { EstabelecimentoProfissionaisCadastro } from './EstabelecimentoProfissionaisCadastro';
import { EstabelecimentoProfissionaisSconApp } from './EstabelecimentoProfissionaisSconApp';

interface IProps extends IPropsEstabelecimentoPagina {
  administradorEstabelecimentoId: number;
}

export const EstabelecimentoProfissionais: React.FC<IProps> = ({ administradorEstabelecimentoId, estabelecimento, refetch }) => {
  const [openDialogCadastro, setOpenDialogCadastro] = useState(false);
  const [openDialogDeletar, setOpenDialogDeletar] = useState(false);
  const [anchorElProfilePopoverNovo, setAnchorElProfilePopoverNovo] = useState<null | HTMLElement>(null);
  const [openDialogNovoProfissionalSconApp, setOpenDialogNovoProfissionalSconApp] = useState(false);
  const [openDialogAdministradorEstabelecimento, setOpenDialogAdministradorEstabelecimento] = useState(false);
  const [id, setId] = useState<number>();

  const { usuarioId } = useUserContext();

  const isPopoverNovoOpen = Boolean(anchorElProfilePopoverNovo);

  const onClickEdit = useCallback((id: number) => {
    setOpenDialogCadastro(true);
    setId(id);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDialogCadastro(false);
    setOpenDialogNovoProfissionalSconApp(false);
    setOpenDialogAdministradorEstabelecimento(false);
    setOpenDialogDeletar(false);
    setId(undefined);
    setAnchorElProfilePopoverNovo(null);
  }, []);

  const onClickDelete = useCallback((id: number) => {
    setOpenDialogDeletar(true);
    setId(id);
  }, []);

  const { mutate: remove, isLoading: isLoadingDelete } = useMutation((id: number) =>
    profissionalEstabelecimentoService.remove(id), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => feedback(String(error), 'error'),
  });

  const onClickConfirmDelete = useCallback(() => {
    id && remove(id);
  }, [id]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={3}
      padding={3}
    >
      <Box display='flex' flex={1} justifyContent='end'>
        <Button
          label='Novo'
          variant='contained'
          size='large'
          onClick={(e) => setAnchorElProfilePopoverNovo(e.currentTarget)}
        />

        <Popover
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          anchorEl={anchorElProfilePopoverNovo}
          open={isPopoverNovoOpen}
          onClose={() => setAnchorElProfilePopoverNovo(null)}
        >
          <Box display='flex' flexDirection='column'>
            <Button
              label='Profissional'
              onClick={() => setOpenDialogCadastro(true)}
              borderRounded={false}
              sx={{ justifyContent: 'initial', color: 'white' }}
            />

            <Button
              label='Profissional SconApp'
              onClick={() => setOpenDialogNovoProfissionalSconApp(true)}
              borderRounded={false}
              sx={{ justifyContent: 'initial', color: 'white' }}
            />

            <Button
              label='Proprietário como profissional'
              onClick={() => setOpenDialogAdministradorEstabelecimento(true)}
              borderRounded={false}
              sx={{ justifyContent: 'initial', color: 'white' }}
              disabled={estabelecimento.profissionais?.some(item => item.profissional?.usuarioId == usuarioId)}
            />
          </Box>
        </Popover>
      </Box>

      <Listagem
        lista={
          estabelecimento.profissionais!.map((item) => ({
            id: item.id,
            descricao: item.nome + (item.profissional?.usuarioId == usuarioId ? ' (Você)' : ''),
            endAdornment: !!item.profissionalId && (
              <Tooltip title='É um usuário SconApp'>
                <img
                  src={SconIcon}
                  alt='logo'
                  height={30}
                  width={30}
                />
              </Tooltip>
            ),
            startAdornment: (
              <Badge badgeContent=''
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: item.cor
                  }
                }}>
                <Avatar src={item.foto}>{item.nome.substring(0, 1)}</Avatar>
              </Badge>
            )
          }))}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />

      <Dialog
        open={openDialogCadastro}
        onClose={handleClose}
      >
        <EstabelecimentoProfissionaisCadastro
          isCreateAdministradorEstabelecimento={false}
          profissionalEstabelecimentoId={id}
          estabelecimento={estabelecimento}
          refetch={refetch}
          onCloseEdit={handleClose}
        />
      </Dialog>

      <Dialog
        open={openDialogNovoProfissionalSconApp}
        onClose={() => setOpenDialogNovoProfissionalSconApp(false)}
      >
        <EstabelecimentoProfissionaisSconApp
          administradorEstabelecimentoId={administradorEstabelecimentoId}
          onCloseEdit={handleClose}
        />
      </Dialog>

      <Dialog
        open={openDialogAdministradorEstabelecimento}
        onClose={handleClose}
      >
        <EstabelecimentoProfissionaisCadastro
          isCreateAdministradorEstabelecimento={true}
          estabelecimento={estabelecimento}
          refetch={refetch}
          onCloseEdit={handleClose}
        />
      </Dialog>

      <DeleteConfirmationDialog
        onClose={handleClose}
        onConfirm={onClickConfirmDelete}
        open={openDialogDeletar}
        isLoading={isLoadingDelete}
        label='Tem certeza que deseja deletar este profissional?'
      />
    </Box>
  );
};