import { Box, Card, CardHeader, Collapse, Icon, IconButton } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { ProfissionalEstabelecimentoServico } from '../../../shared/components/SconApp/profissionalEstabelecimentoServico/ProfissionalEstabelecimentoServico';
import { IProfissionalEstabelecimentoServicoView, IUpdateProfissionalEstabelecimento } from '../../../shared/services/api/profissionalService';
import { IPropsProfissionalPagina } from '../AreaProfissional';
import { Button } from '../../../shared/components/MUI/button/Button';
import { feedback, finalizarRequisicao, profissionalEstabelecimentoService } from '../../../shared/services';
import { useMutation } from '@tanstack/react-query';

interface HorarioProfissionalEstabelecimento {
  [key: number]: {
    horaInicio: Dayjs;
    horaFim: Dayjs;
  };
}

export const AreaProfissionalInformacoes: React.FC<IPropsProfissionalPagina> = ({ profissional, refetch }) => {
  const [open, setOpen] = useState(false);
  const [horarios, setHorarios] = useState<HorarioProfissionalEstabelecimento>({});
  const [profissionalServicos, setProfissionalServicos] = useState<{ [key: number]: IProfissionalEstabelecimentoServicoView[] }>({});

  useEffect(() => {
    if (profissional.profissionalEstabelecimentos) {
      const horariosIniciaisFinais = profissional.profissionalEstabelecimentos.reduce((acc, item) => {
        acc[item.estabelecimentoId] = {
          horaInicio: dayjs(item.horaInicio, 'HH:mm'),
          horaFim: dayjs(item.horaFim, 'HH:mm')
        };
        setProfissionalServicos((prevServicos) => ({
          ...prevServicos,
          [item.estabelecimentoId]: item.profissionalEstabelecimentoServicos || [],
        }));
        return acc;
      }, {} as HorarioProfissionalEstabelecimento);

      setHorarios(horariosIniciaisFinais);
    }
  }, [profissional.profissionalEstabelecimentos]);

  const { mutate: update, isLoading: isLoadingUpdateProfissionalEstabelecimento } = useMutation((profissionalEstabelecimento: IUpdateProfissionalEstabelecimento) =>
    profissionalEstabelecimentoService.update(profissionalEstabelecimento), {
    onSuccess: () => finalizarRequisicao('Salvo com sucesso!', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const handleHoraInicioChange = (estabelecimentoId: number, value: Dayjs) => {
    setHorarios((prevHorarios) => ({
      ...prevHorarios,
      [estabelecimentoId]: {
        ...prevHorarios[estabelecimentoId],
        horaInicio: value,
      },
    }));
  };

  const handleHoraFimChange = (estabelecimentoId: number, value: Dayjs) => {
    setHorarios((prevHorarios) => ({
      ...prevHorarios,
      [estabelecimentoId]: {
        ...prevHorarios[estabelecimentoId],
        horaFim: value,
      },
    }));
  };

  const handleSubmit = useCallback(() => {
    profissional.profissionalEstabelecimentos?.map(item => {
      const stringHoraInicio = horarios[item.estabelecimentoId].horaInicio.format('HH:mm:ss');
      const stringHoraFim = horarios[item.estabelecimentoId].horaFim.format('HH:mm:ss');

      if (stringHoraInicio < item.estabelecimento.horaInicio || stringHoraFim > item.estabelecimento.horaFim) {
        feedback(`O horário do profissional deve estar entre ${item.estabelecimento.horaInicio.substring(0, 5)} e ${item.estabelecimento.horaFim.substring(0, 5)}, que são os horários cadastrados no estabelecimento.`, 'error');
        return;
      }

      const submitData: IUpdateProfissionalEstabelecimento = {
        id: item.id,
        estabelecimentoId: item.estabelecimentoId,
        profissionalId: item.profissionalId,
        horaInicio: stringHoraInicio,
        horaFim: stringHoraFim,
        comissao: item.comissao,
        cor: item.cor,
        profissionalEstabelecimentoServicos: profissionalServicos[item.estabelecimentoId]
      };

      update(submitData);
    });

    refetch && refetch();
  }, [horarios, profissionalServicos]);

  const uniqueEstabelecimento = profissional.profissionalEstabelecimentos?.length === 1;

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Box display='flex' justifyContent='end'>
        <Button label='Salvar' variant='contained' onClick={handleSubmit} isLoading={isLoadingUpdateProfissionalEstabelecimento} />
      </Box>

      {profissional.profissionalEstabelecimentos?.map((item) =>
        <Card key={item.estabelecimentoId} sx={{ paddingX: 2, paddingBottom: 2 }} variant='outlined'>
          <CardHeader
            title={item.estabelecimento?.nome}
            action={
              <Box>

                <IconButton
                  onClick={() => {
                    const redirectUrl = `${window.location.origin}/agendamento/${item.estabelecimento?.id}`;
                    navigator.clipboard.writeText(redirectUrl);
                    feedback('Link para agendamento copiado para a área de transferência.', 'success');
                  }}
                >
                  <Icon>link</Icon>
                </IconButton>

                {!uniqueEstabelecimento &&
                  <IconButton
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
                  </IconButton>}
              </Box>
            }
          />

          {horarios[item.estabelecimentoId] &&
            <Collapse in={uniqueEstabelecimento || open}>
              <Box display='flex' flexDirection='column' gap={2}>
                <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
                  <TimePicker
                    label='Horário de início'
                    sx={{ width: '100%' }}
                    ampm={false}
                    value={horarios[item.estabelecimentoId].horaInicio}
                    onChange={(value: Dayjs | null) => value != null && handleHoraInicioChange(item.estabelecimentoId, value)}
                  />

                  <TimePicker
                    label='Horário de termino'
                    sx={{ width: '100%' }}
                    ampm={false}
                    value={horarios[item.estabelecimentoId].horaFim}
                    onChange={(value: Dayjs | null) => value != null && handleHoraFimChange(item.estabelecimentoId, value)}
                  />
                </Box>

                <ProfissionalEstabelecimentoServico
                  profissionalEstabelecimentoId={item.id}
                  estabelecimentoId={item.estabelecimentoId}
                  servicos={item.estabelecimento?.servicos || []}
                  profissionalServicos={profissionalServicos}
                  setProfissionalServicos={setProfissionalServicos}
                />
              </Box>
            </Collapse>}
        </Card>
      )}
    </Box>
  );
};