import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';
import { useEffect, useState } from 'react';


export type TVTextFieldProps = TextFieldProps & {
  name: string;
  isMinZero?: boolean;
}

export const VTextField: React.FC<TVTextFieldProps> = ({ name, isMinZero = false, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
      clearValue: () => setValue('')
    });
  }, [registerField, fieldName, value]);

  const handleValueChange = (e: any) => {
    const newValue = isMinZero && e.target.value < 0 ? 0 : e.target.value;
    setValue(newValue);
    rest.onChange?.(e);
  };

  return (
    <TextField
      {...rest}
      fullWidth
      error={!!error}
      helperText={error}
      value={value}
      onChange={handleValueChange}
      onKeyDown={(e) => { error && clearError(); rest.onKeyDown?.(e); }}
    />
  );
};