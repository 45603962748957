import { Api } from './axios-config';
import { ICreateProfissionalEstabelecimento, IProfissionalEstabelecimentoView, IUpdateProfissionalEstabelecimento } from './profissionalService';

const getById = async (id: number): Promise<IProfissionalEstabelecimentoView> => {
  try {
    const { data } = await Api.get<IProfissionalEstabelecimentoView>(
      `/profissionalEstabelecimento/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter profissional estabelecimento.');
  }
};

const getByUsuarioEstabelecimento = async (
  usuarioId: number,
  estabelecimentoId: number
): Promise<IProfissionalEstabelecimentoView> => {
  try {
    const { data } = await Api.get(
      `/profissionalEstabelecimento/usuario/${usuarioId}/estabelecimento/${estabelecimentoId}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter estabelecimentos do profissional.');
  }
};

const get = async (): Promise<IProfissionalEstabelecimentoView[]> => {
  try {
    const { data } = await Api.get('/profissionalEstabelecimento');

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter estabelecimentos do profissional.');
  }
};

const create = async (body: ICreateProfissionalEstabelecimento): Promise<number> => {
  try {
    const { data } = await Api.post(
      '/profissionalEstabelecimento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const update = async (body: IUpdateProfissionalEstabelecimento) => {
  try {
    const { data } = await Api.put(
      '/profissionalEstabelecimento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: number) => {
  try {
    await Api.delete(
      `/profissionalEstabelecimento/${id}`
    );

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const getHorariosDisponiveis = async (
  id: number,
  dia: number,
  mes: number,
  duracaoServico: number
): Promise<string[]> => {
  try {
    const queryParams = {
      id: id?.toString(),
      dia: dia?.toString(),
      mes: mes?.toString(),
      duracaoServico: duracaoServico?.toString()
    };

    const { data } = await Api.get('/profissionalEstabelecimento/horariosDisponiveis', {
      params: queryParams,
    });

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter profissional.');
  }
};

export const profissionalEstabelecimentoService = {
  getById,
  get,
  getByUsuarioEstabelecimento,
  create,
  update,
  remove,
  getHorariosDisponiveis
};