import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LOCAL_STORAGE_KEY_REDIRECT_URL,
  useUserContext,
} from '../../../contexts';
import { ICreateUsuario, ILogin, feedback } from '../../../services';
import { Button } from '../../MUI/button/Button';
import { Cadastro } from './components/Cadastro';
import { Login } from './components/Login';
import { GoogleLogin } from '@react-oauth/google';

export interface IFormData {
  nome: string;
  email: string;
  cpf?: string;
  telefone?: number;
  senha: string;
  confirmacaoDeSenha: string;
}

interface ILoginProps {
  children: React.ReactNode;
}

export const LoginPage: React.FC<ILoginProps> = ({ children }) => {
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [mensagemErro, setMensagemErro] = useState<string>();
  const [isCadastro, setIsCadastro] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { isAuthenticated, login, googleLogin, create } = useUserContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const redirectUrl = localStorage.getItem(LOCAL_STORAGE_KEY_REDIRECT_URL);

  const { mutate: mutateLogin, isLoading: isLoadingLogin } = useMutation(
    (usuario: ILogin) => login(usuario),
    {
      onSuccess: (data) => {
        data && setMensagemErro(data);
        navigate(redirectUrl ?? '');
      },
      onError: (error: any) => setMensagemErro(error.message.substring(0, 40)),
    }
  );

  const { mutate: mutateGoogleLogin, isLoading: isLoadingGoogleLogin } = useMutation(
    (jwt: string) => googleLogin(jwt),
    {
      onSuccess: (data) => {
        data && setMensagemErro(data);
        navigate(redirectUrl ?? '');
      },
      onError: (error: any) => setMensagemErro(error.message.substring(0, 40)),
    }
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (usuario: ICreateUsuario) => create(usuario),
    {
      onSuccess: () => {
        feedback('Usuário criado', 'success');
        navigate(redirectUrl ?? '');
      },
      onError: (error: any) => setMensagemErro(error.message.substring(0, 40)),
    }
  );

  const handleClickCadastroLogin = useCallback(() => {
    formRef.current?.reset();
    formRef.current?.setErrors({});
    setMensagemErro('');
    setIsCadastro(!isCadastro);
    setMostrarSenha(false);
  }, [isCadastro]);

  const isLoading = useMemo(() => {
    return isLoadingCreate || isLoadingLogin || isLoadingGoogleLogin;
  }, [isLoadingCreate, isLoadingLogin, isLoadingGoogleLogin]);

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY_REDIRECT_URL,
        window.location.pathname
      );
    }
  }, [isAuthenticated]);

  if (isAuthenticated) return <>{children}</>;

  return (
    <Box
      width='100vw'
      height='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Card>
        <Box padding={2} width={lgDown ? 300 : 500}>
          <CardContent>
            {isCadastro ? (
              <Cadastro
                mensagemErro={mensagemErro}
                mostrarSenha={mostrarSenha}
                setMostrarSenha={setMostrarSenha}
                mutateCreate={mutateCreate}
                isLoadingCreate={isLoading}
              />
            ) : (
              <Login
                mensagemErro={mensagemErro}
                setMensagemErro={setMensagemErro}
                mostrarSenha={mostrarSenha}
                setMostrarSenha={setMostrarSenha}
                mutateLogin={mutateLogin}
                isLoadingLogin={isLoading}
              />
            )}
          </CardContent>

          <CardActions sx={{ padding: 0 }}>
            <Box
              display='flex'
              flexDirection='column'
              gap={2}
              flex={1}
              paddingX={2}
            >
              <Button
                label={isCadastro ? 'Cadastrar' : 'Logar'}
                variant='contained'
                disabled={isLoading}
                isLoading={isLoading}
                type='submit'
                form={isCadastro ? 'cadastro-form' : 'login-form'}
              />

              <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
                <Typography>ou continue com</Typography>

                <GoogleLogin
                  onSuccess={(res) => mutateGoogleLogin(res.credential || '')}
                  onError={() => console.log('ERRO')}
                  useOneTap
                  shape='circle'
                  width='100%'
                />
              </Box>

              <Divider variant='middle' />

              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={2}
              >
                <Typography fontSize={14}>
                  {isCadastro ? 'Já' : 'Não'} possui uma conta?
                </Typography>

                <Button
                  label={isCadastro ? 'Login' : 'Cadastre-se'}
                  variant='text'
                  size='small'
                  disabled={isLoading}
                  onClick={handleClickCadastroLogin}
                />
              </Box>
            </Box>
          </CardActions>
        </Box>
      </Card>
    </Box>
  );
};
