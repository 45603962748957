import React, { useState } from 'react';
import { Icon, List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { ProfissionalTabsEnum } from '../../profissional/AreaProfissional';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface IProps {
  onClickAtalho: (redirect: string, page: number) => void;
  alwaysOpen: boolean;
}

export const HomeWidgetProfissional: React.FC<IProps> = ({ onClickAtalho, alwaysOpen }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Atalhos de profissional" />
        {open || alwaysOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open || alwaysOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={() => onClickAtalho('/profissional', ProfissionalTabsEnum.Informacoes)} sx={{ pl: 4 }}>
            <ListItemIcon>
              <Icon>engineering</Icon>
            </ListItemIcon>
            <ListItemText primary="Área do profissional" />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
