import { Autocomplete, Box, Grid, Icon, IconButton, LinearProgress, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAgendamentoContext, useUserContext } from '../../../contexts';
import { dateFormat } from '../../../helpers/dateFormat';
import { Button } from '../../MUI/button/Button';
import { DayView } from './components/DayView';
import { WeekView } from './components/WeekView';

interface IProps {
  filterLabel: string;
  filterOptions: { id: number, nome: string }[];
  filterOptionType: number;
  estabelecimentoId?: number;
  profissionalId?: number;
  horaInicio: number;
  horaFim: number;
  refetch: () => any;
}

export const SchedulerFilterOptionsEnum = {
  Estabelecimento: 1,
  Profissional: 2
} as const;

export const SchedulerViewEnum = {
  Dia: 1,
  Semana: 2
} as const;

export const getSchedulerViewOptions = () => {
  return Object.keys(SchedulerViewEnum).map((key) => ({
    id: SchedulerViewEnum[key as keyof typeof SchedulerViewEnum],
    descricao: key,
  }));
};

const dayView = getSchedulerViewOptions().find(x => x.id === SchedulerViewEnum.Dia);

export const Scheduler: React.FC<IProps> = ({
  filterLabel,
  filterOptions,
  filterOptionType,
  estabelecimentoId,
  profissionalId,
  horaInicio,
  horaFim,
  refetch
}) => {
  const [view, setView] = useState<any>(dayView);
  const [option, setOption] = useState<{ id: number, nome: string }>();
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { validateEmailConfirmado } = useUserContext();
  const { setAgendamento, setViaScheduler, setBlockEstabelecimento, setBlockProfissional } = useAgendamentoContext();

  const daysToChange = useMemo(() => { return view.id == SchedulerViewEnum.Dia ? 1 : 7; }, [view]);

  const handleAdvanceButtonClick = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToChange);
    setDate(newDate);
  };

  const handleBackButtonClick = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - daysToChange);
    setDate(newDate);
  };

  const uniqueOption = filterOptions.length === 1;

  useEffect(() => {
    if (uniqueOption) {
      setOption(filterOptions[0]);
    }
  }, [uniqueOption, filterOptions]);

  useEffect(() => {
    lgDown && setView(dayView);
  }, [lgDown]);

  const dateView = useMemo(() => {
    if (view.id === SchedulerViewEnum.Dia) {
      return dateFormat(date, 'LL');
    }

    if (view.id === SchedulerViewEnum.Semana) {
      const dateFormated = dateFormat(date, 'MMMM [de] YYYY');
      return dateFormated.charAt(0).toUpperCase() + dateFormated.slice(1);
    }
  }, [view, date]);

  const generateHorarios = () => {
    const horarios = [];
    for (let hour = horaInicio; hour < horaFim; hour++) {
      const hourString = hour.toString().padStart(2, '0');

      const formattedHour = hourString + ':00';
      horarios.push(formattedHour);

      const halfHourFormatted = hourString + ':30';
      horarios.push(halfHourFormatted);
    }
    return horarios;
  };

  const onClickAgendar = () => {
    if (!validateEmailConfirmado()) {
      return;
    }

    setAgendamento({
      estabelecimentoId: (filterOptionType === SchedulerFilterOptionsEnum.Estabelecimento ? option?.id : estabelecimentoId) || 0,
      profissionalEstabelecimentoId: filterOptionType === SchedulerFilterOptionsEnum.Profissional ? option?.id : undefined,
      profissionalId: profissionalId
    });

    setViaScheduler(true);
    setBlockEstabelecimento(true);
    setBlockProfissional(!!profissionalId);

    navigate('/agendamento');
  };

  return (
    <>
      <Grid container spacing={2} paddingBottom={2}>
        {!lgDown &&
          <Grid item xs={12} lg={3} width={200}>
            <Autocomplete
              options={lgDown ? [dayView] : getSchedulerViewOptions()}
              value={view}
              fullWidth
              onChange={(_, value) => value && setView(value)}
              getOptionLabel={(option: any) => option.descricao}
              disabled={lgDown}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Visualização'
                  variant='outlined'
                  size={lgDown ? 'small' : 'medium'}
                />
              )}
            />
          </Grid>
        }

        <Grid item xs={12} lg={3} width={200}>
          <Autocomplete
            key={option ? 'optionSelected' : 'optionNotSelected'}
            options={filterOptions}
            value={option}
            fullWidth
            onChange={(_, value) => setOption(value != null ? value : undefined)}
            getOptionLabel={(option: any) => option.nome}
            disabled={uniqueOption || filterOptions.length == 0}
            renderInput={(params) => (
              <TextField
                {...params}
                label={filterLabel}
                variant='outlined'
                size={lgDown ? 'small' : 'medium'}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            height='100%'
            gap={lgDown ? 1 : 5}
          >
            <IconButton onClick={handleBackButtonClick}>
              <Icon>arrow_back</Icon>
            </IconButton>

            <Typography>{dateView}</Typography>

            <IconButton onClick={handleAdvanceButtonClick}>
              <Icon>arrow_forward</Icon>
            </IconButton>
          </Box>
        </Grid>

        {!lgDown && <Grid item xs={12} lg={2}>
          <Box
            display='flex'
            alignItems='center'
            height='100%'
          >
            <Button
              label='Novo'
              variant='contained'
              size='large'
              fullWidth
              onClick={onClickAgendar}
            />
          </Box>
        </Grid>
        }
      </Grid>

      {isLoading &&
        <LinearProgress />
      }

      {view.id === SchedulerViewEnum.Dia &&
        <DayView
          estabelecimentoId={estabelecimentoId}
          profissionalId={profissionalId}
          filterOptionType={filterOptionType}
          option={option}
          date={date}
          horaInicio={horaInicio}
          horarios={generateHorarios()}
          refetch={refetch}
          setIsLoading={setIsLoading}
        />
      }

      {view.id === SchedulerViewEnum.Semana &&
        <WeekView
          estabelecimentoId={estabelecimentoId}
          profissionalId={profissionalId}
          filterOptionType={filterOptionType}
          option={option}
          date={date}
          horaInicio={horaInicio}
          horarios={generateHorarios()}
          refetch={refetch}
          setIsLoading={setIsLoading}
        />
      }

      {lgDown &&
        <Box position="fixed" bottom={20} right={20} zIndex={1}>
          <Button
            label='+'
            variant='contained'
            onClick={onClickAgendar}
            minWidth={0}
            sx={{
              fontSize: 30,
              borderRadius: '50%',
              width: 64,
              height: 64,
              minWidth: 0,
              padding: 0,
              boxShadow: '2px 4px 12px rgba(0, 0, 0, 1)'
            }}
          />
        </Box>
      }
    </>
  );
};
