import React, { useEffect, useState } from 'react';
import { TVTextFieldProps } from '.';
import { TextField } from '@mui/material';
import { useField } from '@unform/core';

const CPFFormat = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    .substring(0, 14);
};

export const VTextFieldCPF: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
      clearValue: () => setValue('')
    });
  }, [registerField, fieldName, value]);

  const handleCPFChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(CPFFormat(event.target.value));
    rest.onChange?.(event);
  };

  return (
    <TextField
      {...rest}
      fullWidth
      error={!!error}
      helperText={error}
      value={value}
      onChange={handleCPFChange}
      onKeyDown={(e) => { error && clearError(); rest.onKeyDown?.(e); }}
      inputProps={{ maxLength: 14 }}
    />
  );
};
