import { QueryClient } from '@tanstack/react-query';
import { feedback } from '../alertService';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async (err: any) => {
        feedback(String(err), 'error');
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 0,
    },
    mutations: {
      onError: async (err: any) => {
        feedback(String(err), 'error');
      },
    },
  },
});
