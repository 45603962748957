import { Api } from './axios-config';
import { IEstabelecimentoView } from './estabelecimentoService';
import { IUsuarioView } from './usuarioService';

export interface IAdministradorEstabelecimentoView {
  id: number;
  usuarioId: number;
  usuario: IUsuarioView;
  estabelecimentoId: number;
  estabelecimento: IEstabelecimentoView;
}

const getById = async (id: number): Promise<IAdministradorEstabelecimentoView> => {
  try {
    const { data } = await Api.get<IAdministradorEstabelecimentoView>(
      `/administradorEstabelecimento/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter administrador de estabelecimento.');
  }
};

const getByUserId = async (usuarioId: number): Promise<IAdministradorEstabelecimentoView> => {
  try {
    const { data } = await Api.get<IAdministradorEstabelecimentoView>(
      `/administradorEstabelecimento/usuario/${usuarioId}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter administrador de estabelecimento por usuário.');
  }
};

export interface ICreateAdministradorEstabelecimento {
  usuarioId: number;
  estabelecimentoId: number;
}

const create = async (body: ICreateAdministradorEstabelecimento): Promise<number> => {
  try {
    const { data } = await Api.post(
      '/administradorEstabelecimento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export interface IUpdateAdministradorEstabelecimento {
  id: number;
  usuarioId: number;
  estabelecimentoId: number;
}

const update = async (body: IUpdateAdministradorEstabelecimento) => {
  try {
    const { data } = await Api.put(
      '/administradorEstabelecimento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: number) => {
  try {
    await Api.delete(
      `/administradorEstabelecimento/${id}`
    );

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const inviteProfessional = async (id: number, email: string): Promise<string> => {
  try {
    const { data } = await Api.get<string>(
      `/administradorEstabelecimento/${id}/convidar-profissional?email=${email}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao enviar e-mail.');
  }
};

export const administradorEstabelecimentoService = {
  getById,
  getByUserId,
  create,
  update,
  remove,
  inviteProfessional
};