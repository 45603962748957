import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import { LoginPage } from './shared/components';
import { AgendamentoProvider, AppThemeProvider, EstabelecimentoProvider, LoadingProvider, MenuProvider, UserProvider } from './shared/contexts';
import './shared/forms/TraducoesYup';
import { queryClient } from './shared/services/queryClient';

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
          <BrowserRouter>
            <UserProvider>
              <AppThemeProvider>
                <LoadingProvider>
                  <LoginPage>
                    <EstabelecimentoProvider>
                      <AgendamentoProvider>
                        <MenuProvider>
                          <AppRoutes />
                        </MenuProvider>
                      </AgendamentoProvider>
                    </EstabelecimentoProvider>
                  </LoginPage>
                </LoadingProvider>
              </AppThemeProvider>
            </UserProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};
