import { Box, Card, LinearProgress } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { VTextField } from '../../../shared/forms';
import getValidationErrors from '../../../shared/helpers/getValidationErrors';
import { LayoutCadastro } from '../../../shared/layouts';
import { ICreateServico, IUpdateServico, servicoService } from '../../../shared/services';
import { AlertTypes, feedback } from '../../../shared/services/alertService';

const schema = Yup.object().shape({
  descricao: Yup.string().required(),
  valor: Yup.number().required()
});

interface IProps {
  estabelecimentoId: number;
  servicoId?: number;
  refetch: () => void;
  onCloseEdit: () => void;
}

export const EstabelecimentoServicosCadastro: React.FC<IProps> = ({ estabelecimentoId, servicoId, refetch, onCloseEdit }) => {
  const { data: servico, isLoading: isLoadingServico } = useQuery({
    queryKey: ['servico'],
    queryFn: () => {
      if (servicoId) {
        return servicoService.getById(servicoId);
      }
    },
    enabled: !!servicoId,
  });

  const { mutate: incluirServico, isLoading: isLoadingIncluirServico } = useMutation((servico: ICreateServico) =>
    servicoService.create(servico), {
    onSuccess: () => finalizarRequisicao('Servico criado', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const { mutate: editarServico, isLoading: isLoadingEditarServico } = useMutation((servico: IUpdateServico) =>
    servicoService.update(servico), {
    onSuccess: () => finalizarRequisicao('Servico editado', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const finalizarRequisicao = (mensagem: string, tipoFeedback: AlertTypes) => {
    feedback(mensagem, tipoFeedback);

    if (tipoFeedback === 'success') {
      refetch();
      onCloseEdit();
    }
  };

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    servico && formRef.current?.setData(servico);
  }, [servico]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate({ descricao: data.descricao, valor: data.valor }, { abortEarly: false });

        if (servicoId) {
          const submitData: IUpdateServico = {
            id: servicoId,
            estabelecimentoId: estabelecimentoId,
            descricao: data.descricao,
            valor: data.valor
          };

          editarServico(submitData);
          return;
        }

        const submitData: ICreateServico = {
          estabelecimentoId: estabelecimentoId,
          descricao: data.descricao,
          valor: data.valor
        };

        incluirServico(submitData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err as Yup.ValidationError);
          formRef.current?.setErrors(errors);
        }
      }
    }, []);

  return !isLoadingServico || !servicoId ? (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <LayoutCadastro header={!servico ? 'Novo serviço' : 'Editar serviço'} isSubmit isLoading={isLoadingIncluirServico || isLoadingEditarServico}>
        <Box display='flex' flexDirection='column' gap={2}>
          <VTextField name='descricao' label='Descrição' />
          <VTextField name='valor' label='Valor (R$)' type='number' isMinZero />
        </Box>
      </LayoutCadastro>
    </Form>
  ) : (
    <Card sx={{ width: 400, height: 200 }}>
      <LinearProgress />
    </Card>
  );
};