import { Fade, Grid, LinearProgress, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppThemeContext, useLoading } from '../../../contexts';

export const SplashScreen: React.FC = () => {
  const theme = useTheme();
  const { isLoading } = useLoading();
  const { logo } = useAppThemeContext();

  const [progress, setProgress] = useState(0);
  const time = useMemo(() => { return 100; }, []);

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const diff = Math.random() * 10;
          return Math.min(prevProgress + diff, 100);
        });
      }, time);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isLoading, time]);

  return (
    <Fade in={isLoading}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <img
            alt="Logo SCon"
            src={logo}
            style={{
              width: '80px',
              position: 'relative',
              transition: 'transform .2s',
            }}
          />
        </Grid>

        <Grid item>
          <Fade in={isLoading}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                width: theme.spacing(35),
                marginTop: theme.spacing(6),
                colorPrimary: theme.palette.primary.main,
              }}
            />
          </Fade>
        </Grid>

        <Fade in={isLoading}>
          <Grid
            item
            sx={{ marginTop: theme.spacing(3), color: theme.palette.text.primary }}
          >
            <Typography variant="subtitle1">
              Carregando...
            </Typography>
          </Grid>
        </Fade>
      </Grid>
    </Fade>
  );
};
