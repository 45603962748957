import { Subject } from 'rxjs';

export type AlertTypes =
  | 'warning'
  | 'success'
  | 'isLoading'
  | 'error'
  | 'info'
  | undefined;

interface IAlert {
  message: string;
  type: AlertTypes;
  onClose?: () => void;
}

const alertSubject = new Subject<IAlert>();

export const AlertService = alertSubject.asObservable();

export const feedback = (
  message: string,
  type: AlertTypes,
  onClose?: () => void,
) => {
  alertSubject.next({
    message,
    onClose,
    type,
  });
};

export const finalizarRequisicao = (mensagem: string, tipoFeedback: AlertTypes, onSuccess?: () => void) => {
  feedback(mensagem, tipoFeedback);


  if (tipoFeedback === 'success' && onSuccess) {
    onSuccess();
  }
};
