import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { feedback } from '../../../services';
import { Button } from '../../MUI/button/Button';

interface DeleteConfirmationDialogProps {
  open: boolean;
  isLoading: boolean;
  label?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({ open, isLoading, label, onClose, onConfirm }) => {
  const handleConfirm = useCallback(async () => {
    try {
      onConfirm();
      onClose();
    } catch (error) {
      feedback('Erro ao deletar registro.', 'error');
    }
  }, [onConfirm, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography>{label ?? 'Tem certeza que deseja deletar este registro?'}</Typography>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='center' flex={1} gap={1}>
          <Button borderRounded={false} color='secondary' label='Não' onClick={onClose} />
          <Button borderRounded={false} color='secondary' label='Sim' onClick={handleConfirm} isLoading={isLoading} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
