import { TextField } from '@mui/material';
import { useField } from '@unform/core';
import React, { useEffect, useState } from 'react';
import { TVTextFieldProps } from '.';

export const VTextFieldPercentage: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
  const { fieldName, registerField, defaultValue } = useField(name);

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => {
        if (!value.trim()) return undefined;
        return parseInt(value);
      },
      setValue: (_, newValue) => setValue(newValue ? newValue.toString() : ''),
      clearValue: () => setValue('')
    });
  }, [registerField, fieldName, value]);

  const handlePercentageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    const regexDecimalMaxLength3 = /^\d{0,3}$/;

    if (regexDecimalMaxLength3.test(newValue) || newValue === '') {
      const intValue = parseInt(newValue);
      if (newValue === '' || (intValue >= 0 && intValue <= 100)) {
        setValue(newValue);
      }
    }
    rest.onChange?.(event);
  };

  return (
    <TextField
      {...rest}
      fullWidth
      value={value}
      onChange={handlePercentageChange}
      InputProps={{
        endAdornment: '%',
      }}
      onKeyDown={(e) => { rest.onKeyDown?.(e); }}
    />
  );
};
