import { Box, Dialog, Typography } from '@mui/material';
import { Button } from '../../MUI/button/Button';
import { useNavigate } from 'react-router-dom';

export const Forbidden: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Dialog
      open
    >
      <Box height={200} width={400} display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
        <Typography>Você não tem permissão para acessar esta tela.</Typography>
        <Button label='Página inicial' variant='contained' onClick={() => navigate('/home')} />
      </Box>
    </Dialog>
  );
};