import { Box, Card, Dialog, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IAgendamentoView } from '../../../../services';
import { EventDetails } from './EventDetails';

interface IEventProps {
  firstTime: number;
  currentEvent: IAgendamentoView;
  heights: { [id: number]: number };
  previousEvents: { [id: number]: IAgendamentoView[] };
  hourHeight: number;
  maxLengthDescription: number;
  refetch: () => any;
}

export const Event: React.FC<IEventProps> = ({
  firstTime,
  currentEvent,
  heights,
  previousEvents,
  hourHeight,
  maxLengthDescription,
  refetch
}) => {
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const calculateTop = () => {
    const [horasInicio, minutosInicio] = currentEvent.horaInicio.split(':').map(Number);

    const totalMinInicio = horasInicio * 60 + minutosInicio;
    const top = ((totalMinInicio / 60) - firstTime) * hourHeight;

    return top;
  };

  const top = calculateTop();

  const width = 100 / (previousEvents[currentEvent.id].length + 1);

  const marginLeft = width * previousEvents[currentEvent.id].length;

  const title = `${currentEvent.nomeCliente} | ${currentEvent.nomeProfissional} | ${currentEvent.horaInicio} às ${currentEvent.horaFim}`;

  const shortTitle = title.length > maxLengthDescription
    ? `${title.substring(0, maxLengthDescription)}...`
    : title;

  const services = currentEvent.servicos.map(s => s.descricao).join(', ');

  return (
    <>
      <Tooltip title={title}>
        <Card
          key={currentEvent.id}
          onClick={() => setOpenDialogEdit(true)}
          sx={{
            position: 'absolute',
            top: `${top}px`,
            marginLeft: `${marginLeft}%`,
            width: `${width}%`,
            height: `${heights[currentEvent.id]}px`,
            zIndex: previousEvents[currentEvent.id].length,
            border: '1px solid',
            overflow: 'hidden',
            '&:hover:after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
              zIndex: previousEvents[currentEvent.id].length + 1,
            },
          }}
        >
          <Box
            height='100%'
            padding={0.5}
            paddingLeft={1}
            sx={{
              backgroundColor: currentEvent.corProfissional,
            }}
          >
            <Typography variant='body2'>{shortTitle}</Typography>
            <Typography variant='body2'>{services}</Typography>
          </Box>
        </Card>
      </Tooltip >

      <Dialog
        open={openDialogEdit}
        onClose={() => setOpenDialogEdit(false)}
      >
        <EventDetails
          agendamento={currentEvent}
          refetch={refetch}
          onClose={() => setOpenDialogEdit(false)}
        />
      </Dialog>
    </>
  );
};