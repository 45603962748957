import Typography from '@mui/material/Typography';
import {
  Box,
  Paper,
} from '@mui/material';
import { dateFormat } from '../../../shared/helpers/dateFormat';
import React from 'react';
import { IAgendamentoView } from '../../../shared/services';

interface IProps {
  children?: React.ReactNode;
  agendamento: IAgendamentoView;
  disabled: boolean;
  colorDisabled: string;
}

export const HomeAgendamentosAgendamento: React.FC<IProps> = ({ children, agendamento, disabled, colorDisabled }) => {
  return (
    <>
      <Box key={agendamento.id} display='flex' flexDirection='column' gap={2}>
        <Paper
          variant='outlined'
          sx={{ padding: 1, lightingColor: 'secondary' }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            paddingRight={3}
          >
            <Box>
              <Typography color={disabled ? colorDisabled : 'none'} variant='h6'>
                {agendamento.nomeEstabelecimento}
              </Typography>
              <Typography color={disabled ? colorDisabled : 'none'}>
                {`${agendamento.servicos.map(x => x.descricao).join(' - ')} - ${agendamento.nomeProfissional}`}
              </Typography>
              <Typography color={disabled ? colorDisabled : 'none'}>
                {dateFormat(agendamento.data, 'LLL')}
              </Typography>
              <Typography color={disabled ? colorDisabled : 'none'}>
                {agendamento.enderecoEstabelecimento}
              </Typography>
            </Box>

            {children}
          </Box>
        </Paper>
      </Box>
    </>
  );
};
