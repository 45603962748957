import { Autocomplete, Box, LinearProgress, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Environment } from '../../../../environment';
import { dateFormat } from '../../../../helpers/dateFormat';
import { LayoutCadastro } from '../../../../layouts';
import { IProdutoView, IServicoView, IUpdateAgendamento, agendamentoService, finalizarRequisicao, produtoService, servicoService } from '../../../../services';

interface IProps {
  agendamentoId: number;
  refetch: () => void;
  onCloseEdit: () => void;
}

export const FinanceiroAgendamentoCadastro: React.FC<IProps> = ({ agendamentoId, refetch, onCloseEdit }) => {
  const [servicosSelecionados, setServicosSelecionados] = useState<IServicoView[]>([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState<IProdutoView[]>([]);

  const { data: agendamento, isFetching: isFetchingAgendamento } = useQuery({
    queryKey: ['agendamento', agendamentoId],
    queryFn: () => agendamentoService.getById(agendamentoId),
    enabled: !!agendamentoId
  });

  const { isFetching: isFetchingServicosAgendamento } = useQuery({
    queryKey: ['servico', agendamento?.servicos],
    queryFn: () => servicoService.getByIds(agendamento?.servicos?.map(x => x.id) || []),
    enabled: !!agendamento?.servicos && agendamento.servicos.length > 0,
    onSuccess(data) {
      setServicosSelecionados(data);
    },
  });

  const { isFetching: isFetchingProdutosAgendamento } = useQuery({
    queryKey: ['produto', agendamento?.produtos],
    queryFn: () => produtoService.getByIds(agendamento?.produtos?.map(x => x.id) || []),
    enabled: !!agendamento?.produtos && agendamento.produtos.length > 0,
    onSuccess(data) {
      setProdutosSelecionados(data);
    },
  });

  const { data: servicos, isFetching: isFetchingServicos } = useQuery({
    queryKey: ['servico', agendamento?.estabelecimentoId],
    queryFn: () => servicoService.getByEstabelecimento(agendamento?.estabelecimentoId || 0),
    enabled: !!agendamento?.estabelecimentoId
  });

  const { data: produtos, isFetching: isFetchingProdutos } = useQuery({
    queryKey: ['produto', agendamento?.estabelecimentoId],
    queryFn: () => produtoService.getByEstabelecimento(agendamento?.estabelecimentoId || 0),
    enabled: !!agendamento?.estabelecimentoId
  });

  const { mutate: finalizarAgendamento, isLoading: isLoadingFinalizarAgendamento } = useMutation((agendamento: IUpdateAgendamento) =>
    agendamentoService.update(agendamento), {
    onSuccess: () => finalizarRequisicao('Profissional estabelecimento editado', 'success', () => {
      refetch();
      onCloseEdit();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const valorTotalServicos = useMemo(() => servicosSelecionados.reduce((total, servico) => total + (servico.valor), 0), [servicosSelecionados]);

  const valorTotalProdutos = useMemo(() => produtosSelecionados.reduce((total, produto) => total + (produto.valor), 0), [produtosSelecionados]);

  const valorTotal = valorTotalServicos + valorTotalProdutos;

  const handleSubmit = useCallback(async () => {
    if (agendamento) {
      const submitData: IUpdateAgendamento = {
        id: agendamento.id,
        cancelado: agendamento.cancelado,
        data: agendamento.data,
        estabelecimentoId: agendamento.estabelecimentoId,
        profissionalEstabelecimentoId: agendamento.profissionalEstabelecimentoId,
        servicos: servicosSelecionados.map(x => x.id),
        produtos: produtosSelecionados.map(x => x.id),
        usuarioId: agendamento.usuarioId,
        finalizado: true
      };

      finalizarAgendamento(submitData);
    }
  }, [agendamento, valorTotal]);

  const isFetching = useMemo(() => {
    return isFetchingAgendamento || isFetchingServicosAgendamento || isFetchingProdutosAgendamento || isFetchingServicos || isFetchingProdutos;
  }, [isFetchingAgendamento, isFetchingServicosAgendamento, isFetchingProdutosAgendamento, isFetchingServicos, isFetchingProdutos]);

  return (
    <Box>
      <LayoutCadastro
        header='Finalizar agendamento'
        onClickSalvar={handleSubmit}
        isLoading={isLoadingFinalizarAgendamento}
        disabled={agendamento?.finalizado}
      >
        {isFetching &&
          <LinearProgress />
        }

        {agendamento && servicos && produtos && !isFetching &&
          <Box display='flex' flexDirection='column' gap={2}>
            <TextField
              label='Cliente'
              value={agendamento.nomeCliente}
              disabled
            />

            <TextField
              label='Data'
              value={dateFormat(agendamento.data, 'LLL')}
              disabled
            />

            <Autocomplete
              multiple
              disabled={agendamento.finalizado}
              options={servicos.filter(x => !servicosSelecionados.some(y => x.id == y.id)) || []}
              getOptionLabel={opt => `${opt.descricao} - R$${opt.valor}`}
              onChange={(_, value) => setServicosSelecionados(value)}
              value={servicosSelecionados}
              noOptionsText={Environment.listagemVazia}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Serviços'
                  variant='outlined'
                />
              )}
            />

            <Autocomplete
              multiple
              disabled={agendamento.finalizado}
              options={produtos.filter(x => !produtosSelecionados.some(y => x.id == y.id)) || []}
              getOptionLabel={opt => `${opt.descricao} - R$${opt.valor}`}
              onChange={(_, value) => setProdutosSelecionados(value)}
              value={produtosSelecionados}
              noOptionsText={Environment.listagemVazia}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Produtos'
                  variant='outlined'
                />
              )}
            />

            <Box display='flex' justifyContent='end'>
              <Typography>Valor total: R${valorTotal}</Typography>
            </Box>
          </Box>
        }
      </LayoutCadastro>
    </Box>
  );
};