import { Avatar, Box, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { DeleteConfirmationDialog } from '../../../shared/components/SconApp/delete-confirmation/DeleteConfirmationDialog';
import { Listagem } from '../../../shared/components/SconApp/listagem/Listagem';
import { useUserContext } from '../../../shared/contexts';
import { administradorEstabelecimentoService } from '../../../shared/services';
import { feedback } from '../../../shared/services/alertService';
import { IPropsEstabelecimentoPagina } from '../Estabelecimento';
import { SconIcon } from '../../../shared/assets';

export const EstabelecimentoAdministradores: React.FC<IPropsEstabelecimentoPagina> = ({ estabelecimento, refetch }) => {
  const [openDialogDeletar, setOpenDialogDeletar] = useState(false);
  const [id, setId] = useState<number>();

  const { usuarioId } = useUserContext();

  const handleClose = useCallback(() => {
    setOpenDialogDeletar(false);
    setId(undefined);
  }, []);

  const onClickDelete = useCallback((id: number) => {
    setOpenDialogDeletar(true);
    setId(id);
  }, []);

  const { mutate: remove, isLoading: isLoadingDelete } = useMutation((id: number) =>
    administradorEstabelecimentoService.remove(id), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => feedback(String(error), 'error'),
  });

  const onClickConfirmDelete = useCallback(() => {
    id && remove(id);
  }, [id]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={3}
      padding={3}
    >
      <Listagem
        lista={
          estabelecimento.administradores!.map((item) => ({
            id: item.id,
            descricao: item.usuario.nome + (item.usuarioId == usuarioId ? ' (Você)' : ''),
            endAdornment: (
              <Tooltip title='É um usuário SconApp'>
                <img
                  src={SconIcon}
                  alt='logo'
                  height={30}
                  width={30}
                />
              </Tooltip>
            ),
            startAdornment: (
              <Avatar src={item.usuario.foto}>{item.usuario.nome.substring(0, 1)}</Avatar>
            ),
            disabled: item.usuarioId == usuarioId
          }))}
        onClickDelete={onClickDelete}
      />

      <DeleteConfirmationDialog
        onClose={handleClose}
        onConfirm={onClickConfirmDelete}
        open={openDialogDeletar}
        isLoading={isLoadingDelete}
        label='Tem certeza que deseja deletar este profissional?'
      />
    </Box>
  );
};