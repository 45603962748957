import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { FinanceiroAgendamento } from '../../../shared/components/SconApp/financeiro-agendamento/FinanceiroAgendamento';
import { agendamentoService } from '../../../shared/services';
import { IPropsProfissionalPagina } from '../AreaProfissional';

export const AreaProfissionalFinancas: React.FC<IPropsProfissionalPagina> = ({ profissional }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { data: agendamentos, refetch, isFetching } = useQuery({
    queryKey: ['agendamentos', profissional],
    queryFn: () => {
      return agendamentoService.get(undefined, undefined, profissional.id);
    }
  });

  const agendamentosPassados = useMemo(() => {
    return agendamentos?.filter((item) => item.passado && !item.cancelado) || [];
  }, [agendamentos]);

  return isFetching ? (
    <CircularProgress />
  ) : (
    <Box display='flex' flexDirection='column' gap={2}>
      <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)} variant='fullWidth'>
        <Tab
          label='Não finalizados'
        />
        <Tab
          label='Finalizados'
        />
      </Tabs>

      {tabIndex == 0 &&
        <FinanceiroAgendamento
          agendamentos={agendamentosPassados.filter(x => !x.finalizado) || []}
          refetch={refetch}
          finalizados={false}
        />
      }

      {tabIndex == 1 &&
        <FinanceiroAgendamento
          agendamentos={agendamentosPassados.filter(x => x.finalizado) || []}
          refetch={refetch}
          finalizados
        />
      }
    </Box>
  );
};