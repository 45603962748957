import { Box, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SplashScreen } from '../../shared/components';
import { Button } from '../../shared/components/MUI/button/Button';
import { LOCAL_STORAGE_KEY_USUARIO_TIPOS, TipoUsuarioEnum, useUserContext } from '../../shared/contexts';
import { ICreateProfissional, ICreateProfissionalEstabelecimento, estabelecimentoService, profissionalEstabelecimentoService, profissionalService } from '../../shared/services';
import { AlertTypes, feedback } from '../../shared/services/alertService';
import { authService } from '../../shared/services/api/authService';

export const CreateProfessional: React.FC = () => {
  const [horaInicio, setHoraInicio] = useState<Dayjs>();
  const [horaFim, setHoraFim] = useState<Dayjs>();

  const { estabelecimentoId } = useParams<{ estabelecimentoId: string }>();
  const { setTiposUsuario, tiposUsuario } = useUserContext();
  const theme = useTheme();

  const navigate = useNavigate();

  const { data: estabelecimento } = useQuery({
    queryKey: ['estabelecimento', estabelecimentoId],
    queryFn: () => {
      if (estabelecimentoId) {
        return estabelecimentoService.getById(parseInt(estabelecimentoId));
      }
    },
    enabled: !!estabelecimentoId,
  });

  const { data: usuario } = useQuery(
    ['usuario'],
    () => authService.getUserFromCookie()
  );

  const { data: profissionalEstabelecimento } = useQuery({
    queryKey: ['usuario', usuario, estabelecimento],
    queryFn: () => {
      if (usuario && estabelecimento) {
        return profissionalEstabelecimentoService.getByUsuarioEstabelecimento(usuario.id, estabelecimento.id);
      }
    },
    enabled: !!usuario && !!estabelecimento,
  });

  const { mutate: create, isLoading } = useMutation((profissional: ICreateProfissional) =>
    profissionalService.create(profissional), {
    onSuccess: () => finalizarRequisicao('Informações salvas', 'success'),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const finalizarRequisicao = (mensagem: string, tipoFeedback: AlertTypes) => {
    feedback(mensagem, tipoFeedback);

    if (tipoFeedback === 'success') {
      if (!tiposUsuario.includes(TipoUsuarioEnum.Profissional)) {
        const tiposUsuarioNewArray = [...tiposUsuario, TipoUsuarioEnum.Profissional];
        setTiposUsuario(tiposUsuarioNewArray);
        localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_TIPOS, JSON.stringify(tiposUsuarioNewArray));
      }

      navigate('/home');
    }
  };

  const createProfissional = useCallback(() => {
    if (!horaInicio || !horaFim) {
      feedback('Os campos horário de início e horário de término são obrigatórios!', 'error');
      return;
    }

    if (usuario && estabelecimentoId) {
      const profissionalEstabelecimento: ICreateProfissionalEstabelecimento = {
        estabelecimentoId: parseInt(estabelecimentoId),
        horaInicio: horaInicio.format('HH:mm'),
        horaFim: horaFim.format('HH:mm'),
        comissao: 100,
        cor: theme.palette.primary.main
      };

      const profissional: ICreateProfissional = {
        usuarioId: usuario.id,
        profissionalEstabelecimento: profissionalEstabelecimento
      };

      create(profissional);
    }
  }, [usuario, horaInicio, horaFim, estabelecimentoId]);

  return estabelecimento && usuario ? (
    <Dialog open>
      <DialogContent>
        <Box display='flex' alignItems='center' flexDirection='column' gap={2}>
          {profissionalEstabelecimento ?
            <>
              <Typography>Ops! Parece que você já é profissional deste estabelecimento.</Typography>
              {/* <Link href=''>Clique aqui para voltar para a página inicial</Link> */}
            </>
            :
            <>
              <Typography>Deseja se cadastrar como profissional do estabelecimento {estabelecimento.nome}?</Typography>

              <Typography variant='body2'>Para confirmar, digite seu horário de trabalho. (Pode ser alterado no futuro)</Typography>

              <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
                <TimePicker
                  label='Horário de início'
                  sx={{ width: '100%' }}
                  ampm={false}
                  value={horaInicio}
                  onChange={(value: any) => setHoraInicio(value)}
                />

                <TimePicker
                  label='Horário de termino'
                  sx={{ width: '100%' }}
                  ampm={false}
                  value={horaFim}
                  onChange={(value: any) => setHoraFim(value)}
                />
              </Box>
            </>
          }
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='center' flex={1} gap={1}>
          {profissionalEstabelecimento ?
            <Button
              borderRounded
              variant='contained'
              label='Página inicial'
              onClick={() => navigate('/home')}
            />
            :
            <Button
              borderRounded
              variant='contained'
              label='Confirmar'
              onClick={createProfissional}
              isLoading={isLoading}
            />
          }
        </Box>
      </DialogActions>
    </Dialog>
  ) : (
    <SplashScreen />
  );
};
