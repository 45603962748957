import { IAdministradorEstabelecimentoView } from './administradorEstabelecimentoService';
import { Api } from './axios-config';
import { IProdutoView } from './produtoService';
import { IProfissionalEstabelecimentoView } from './profissionalService';
import { IServicoView } from './servicoService';

export interface IEstabelecimentoView {
  id: number;
  nome: string;
  endereco: string;
  horaInicio: string;
  horaFim: string;
  cnpj?: string;
  foto?: string;
  profissionais?: IProfissionalEstabelecimentoView[];
  administradores: IAdministradorEstabelecimentoView[];
  servicos?: IServicoView[];
  produtos?: IProdutoView[];
}

const getById = async (id: number): Promise<IEstabelecimentoView> => {
  try {
    const { data } = await Api.get<IEstabelecimentoView>(
      `/estabelecimento/${id}`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter estabelecimento.');
  }
};

const get = async (): Promise<IEstabelecimentoView[]> => {
  try {
    const { data } = await Api.get<IEstabelecimentoView[]>(
      '/estabelecimento'
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao obter estabelecimentos.');
  }
};

export interface ICreateEstabelecimento {
  nome: string;
  endereco: string;
  horaInicio: string;
  horaFim: string;
  cnpj?: string;
}

const create = async (body: ICreateEstabelecimento): Promise<number> => {
  try {
    const { data } = await Api.post(
      '/estabelecimento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export interface IUpdateEstabelecimento {
  id: number;
  nome: string;
  endereco: string;
  horaInicio: string;
  horaFim: string;
  cnpj?: string;
  foto?: string;
}

const update = async (body: IUpdateEstabelecimento) => {
  try {
    const { data } = await Api.put(
      '/estabelecimento',
      body
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: number) => {
  try {
    await Api.delete(
      `/estabelecimento/${id}`
    );

  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const uploadPhoto = async (id: number, file: FormData): Promise<string> => {
  try {
    const { data } = await Api.post<string>(
      `/estabelecimento/${id}/upload`, file
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao salvar foto.');
  }
};

const deletePhoto = async (id: number): Promise<string> => {
  try {
    const { data } = await Api.get<string>(
      `/estabelecimento/${id}/deletePhoto`
    );

    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao deletar foto.');
  }
};

export const estabelecimentoService = {
  getById,
  get,
  create,
  update,
  remove,
  uploadPhoto,
  deletePhoto
};