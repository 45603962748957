import { Avatar, Badge, Box, Icon, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useMutation } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button } from '../../../shared/components/MUI/button/Button';
import PhotoUpload from '../../../shared/components/SconApp/photo-upload/PhotoUpload';
import { VTextField, VTextFieldCNPJ } from '../../../shared/forms';
import getValidationErrors from '../../../shared/helpers/getValidationErrors';
import { IUpdateEstabelecimento, estabelecimentoService } from '../../../shared/services';
import { feedback, finalizarRequisicao } from '../../../shared/services/alertService';
import { IPropsEstabelecimentoPagina } from '../Estabelecimento';

const schema = Yup.object().shape({
  nome: Yup.string().required(),
  endereco: Yup.string().required(),
  cnpj: Yup.string()
});

export const EstabelecimentoInformacoes: React.FC<IPropsEstabelecimentoPagina> = ({ estabelecimento, refetch }) => {
  const [fotoEstabelecimento, setFotoEstabelecimento] = useState<string>();
  const [openDialogUploadFile, setOpenDialogUploadFile] = useState(false);
  const [horaInicio, setHoraInicio] = useState<Dayjs>();
  const [horaFim, setHoraFim] = useState<Dayjs>();

  const formRef = useRef<FormHandles>(null);
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { mutate: updateEstabelecimento, isLoading: isLoadingUpdateEstabelecimento } = useMutation((estabelecimento: IUpdateEstabelecimento) =>
    estabelecimentoService.update(estabelecimento), {
    onSuccess: () => finalizarRequisicao('Informações salvas', 'success', () => {
      refetch();
    }),
    onError: (error: any) => finalizarRequisicao(String(error), 'error'),
  });

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate({ nome: data.nome, endereco: data.endereco, cnpj: data.cnpj }, { abortEarly: false });

      if (!horaInicio || !horaFim) {
        feedback('Os campos horário de início e horário de término são obrigatórios!', 'error');
        return;
      }

      const submitData: IUpdateEstabelecimento = {
        id: estabelecimento.id,
        nome: data.nome,
        endereco: data.endereco,
        cnpj: data.cnpj,
        horaInicio: horaInicio.format('HH:mm:ss'),
        horaFim: horaFim.format('HH:mm:ss'),
        foto: estabelecimento.foto
      };

      updateEstabelecimento(submitData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, [estabelecimento, horaInicio, horaFim]);

  useEffect(() => {
    setFotoEstabelecimento(estabelecimento.foto);
    setHoraInicio(dayjs(estabelecimento.horaInicio, 'HH:mm'));
    setHoraFim(dayjs(estabelecimento.horaFim, 'HH:mm'));
  }, [estabelecimento]);

  const onClose = () => {
    setOpenDialogUploadFile(false);
  };

  const onDelete = () => {
    setFotoEstabelecimento('');
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={estabelecimento}>
      <Box
        display='flex'
        flexDirection='column'
        gap={3}
        alignItems={lgDown ? 'center' : 'inherit'}
      >
        <Box display='flex' justifyContent='space-between' alignItems='start'>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <IconButton
                onClick={() => setOpenDialogUploadFile(true)}
              >
                <Icon>photo_camera</Icon>
              </IconButton>
            }
          >
            <Avatar
              src={fotoEstabelecimento}
              sx={{ height: 150, width: 150 }}
            />
          </Badge>

          <IconButton
            onClick={() => {
              const redirectUrl = `${window.location.origin}/agendamento/${estabelecimento.id}`;
              navigator.clipboard.writeText(redirectUrl);
              feedback('Link para agendamento copiado para a área de transferência.', 'success');
            }}
          >
            <Icon>link</Icon>
          </IconButton>
        </Box>

        <VTextField name='nome' label='Nome' />
        <VTextFieldCNPJ name='cnpj' label='CNPJ' />
        <VTextField name='endereco' label='Endereco' />

        <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
          <TimePicker
            label='Horário de início'
            sx={{ width: '100%' }}
            ampm={false}
            value={horaInicio}
            onChange={(value: any) => setHoraInicio(value)}
          />

          <TimePicker
            label='Horário de termino'
            sx={{ width: '100%' }}
            ampm={false}
            value={horaFim}
            onChange={(value: any) => setHoraFim(value)}
          />
        </Box>

        <Box display='flex' justifyContent='end'>
          <Button label='Salvar' variant='contained' type='submit' isLoading={isLoadingUpdateEstabelecimento} />
        </Box>
      </Box>

      <PhotoUpload
        open={openDialogUploadFile}
        id={estabelecimento.id}
        refetch={refetch}
        uploadPhoto={estabelecimentoService.uploadPhoto}
        deletePhoto={estabelecimentoService.deletePhoto}
        onDelete={onDelete}
        onClose={onClose}
      />
    </Form >
  );
};