import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ICreateUsuario, ILogin, authService } from '../services/api/authService';
import { IUsuarioView } from '../services/api/usuarioService';
import { feedback } from '../services/alertService';
import { useNavigate } from 'react-router-dom';

interface IUserContextData {
  isAuthenticated: boolean;
  usuarioId: number;
  tiposUsuario: number[];
  setTiposUsuario: Dispatch<SetStateAction<number[]>>;
  fotoUsuario: string | undefined;
  setFotoUsuario: Dispatch<SetStateAction<string | undefined>>;
  nomeUsuario: string;
  setNomeUsuario: Dispatch<SetStateAction<string>>;
  emailConfirmado: boolean;
  setEmailConfirmado: Dispatch<SetStateAction<boolean>>;
  validateEmailConfirmado: () => boolean;
  login: (usuario: ILogin) => Promise<string | void>;
  googleLogin: (jwt: string) => Promise<string | void>;
  create: (usuario: ICreateUsuario) => Promise<string | void>;
  logout: () => void;
}

const UserContext = createContext({} as IUserContextData);

export const useUserContext = () => {
  return useContext(UserContext);
};

export const LOCAL_STORAGE_KEY_USUARIO_ID = 'ID';
export const LOCAL_STORAGE_KEY_USUARIO_TIPOS = 'TIPOS';
export const LOCAL_STORAGE_KEY_USUARIO_NOME = 'NOME';
export const LOCAL_STORAGE_KEY_USUARIO_FOTO = 'FOTO';
export const LOCAL_STORAGE_KEY_USUARIO_EMAIL_CONFIRMADO = 'EMAIL_CONFIRMADO';
export const LOCAL_STORAGE_KEY_REDIRECT_URL = 'REDIRECT_URL';

export const setLocalStorage = (user?: IUsuarioView) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_ID, JSON.stringify(user?.id || 0));
  localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_TIPOS, JSON.stringify(user?.tipos || 0));
  localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_NOME, JSON.stringify(user?.nome || ''));
  localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_FOTO, JSON.stringify(user?.foto || ''));
  localStorage.setItem(LOCAL_STORAGE_KEY_USUARIO_EMAIL_CONFIRMADO, JSON.stringify(user?.emailConfirmado || false));
};

export enum TipoUsuarioEnum {
  Profissional = 1,
  AdministradorEstabelecimento = 2,
  AdministradorSistema = 3
}

type Props = {
  children?: React.ReactNode,
};

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [usuarioId, setUsuarioId] = useState(0);
  const [tiposUsuario, setTiposUsuario] = useState<number[]>([]);
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [fotoUsuario, setFotoUsuario] = useState<string>();
  const [emailConfirmado, setEmailConfirmado] = useState(false);

  const navigate = useNavigate();

  const validateEmailConfirmado = () => {
    if (!emailConfirmado) {
      feedback('Confirme seu e-mail para utilizar esta funcionalidade.', 'error');
    }

    return emailConfirmado;
  };

  const setUserProps = (user?: IUsuarioView) => {
    setUsuarioId(user?.id || 0);
    setTiposUsuario(user?.tipos || []);
    setNomeUsuario(user?.nome || '');
    setFotoUsuario(user?.foto || '');
    setEmailConfirmado(user?.emailConfirmado || false);
  };

  useEffect(() => {
    const idUsuarioStorage = localStorage.getItem(LOCAL_STORAGE_KEY_USUARIO_ID);
    const nomeUsuarioStorage = localStorage.getItem(LOCAL_STORAGE_KEY_USUARIO_NOME);
    const tiposUsuarioStorage = localStorage.getItem(LOCAL_STORAGE_KEY_USUARIO_TIPOS);
    const fotoUsuarioStorage = localStorage.getItem(LOCAL_STORAGE_KEY_USUARIO_FOTO);
    const emailConfirmadoStorage = localStorage.getItem(LOCAL_STORAGE_KEY_USUARIO_EMAIL_CONFIRMADO);

    const user: IUsuarioView = {
      id: idUsuarioStorage ? JSON.parse(idUsuarioStorage) as number : 0,
      tipos: tiposUsuarioStorage ? JSON.parse(tiposUsuarioStorage) as number[] : [],
      nome: nomeUsuarioStorage ? JSON.parse(nomeUsuarioStorage) as string : '',
      foto: fotoUsuarioStorage ? JSON.parse(fotoUsuarioStorage) as string : '',
      emailConfirmado: emailConfirmadoStorage ? JSON.parse(emailConfirmadoStorage) as boolean : false,
      primeiroNome: '',
      email: '',
      sexo: 0,
      agendamentos: []
    };

    setUserProps(user);
  }, []);

  const handleLogin = useCallback(async (login: ILogin) => {
    const retorno = await authService.login(login);

    if (retorno instanceof Error) {
      return retorno.message;
    }

    setLocalStorage(retorno);
    setUserProps(retorno);
  }, []);

  const handleGoogleLogin = useCallback(async (jwt: string) => {
    const retorno = await authService.googleLogin(jwt);

    if (retorno instanceof Error) {
      return retorno.message;
    }

    setLocalStorage(retorno);
    setUserProps(retorno);
  }, []);

  const handleCadastro = useCallback(async (cadastroUsuario: ICreateUsuario) => {
    const result = await authService.create(cadastroUsuario);

    if (result instanceof Error) {
      return result.message;
    }

    setLocalStorage(result);
    setUserProps(result);
  }, []);

  const handleLogout = useCallback(async () => {
    await authService.logout();
    setLocalStorage();
    setUserProps();
    localStorage.setItem(LOCAL_STORAGE_KEY_REDIRECT_URL, JSON.stringify(''));
    navigate('home');
  }, []);

  const isAuthenticated = useMemo(() => usuarioId !== 0, [usuarioId]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        usuarioId,
        tiposUsuario,
        setTiposUsuario,
        emailConfirmado,
        setEmailConfirmado,
        validateEmailConfirmado,
        fotoUsuario,
        setFotoUsuario,
        nomeUsuario,
        setNomeUsuario,
        login: handleLogin,
        googleLogin: handleGoogleLogin,
        create: handleCadastro,
        logout: handleLogout
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
