import {
  Box,
  CircularProgress,
  Icon,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Forbidden } from '../../shared/components/SconApp/forbidden/Forbidden';
import {
  TipoUsuarioEnum,
  useEstabelecimentoContext,
  useUserContext,
} from '../../shared/contexts';
import { LayoutBase, LayoutCadastro } from '../../shared/layouts';
import {
  IEstabelecimentoView,
  estabelecimentoService,
  administradorEstabelecimentoService,
} from '../../shared/services';
import {
  EstabelecimentoAdministradores,
  EstabelecimentoAgenda,
  EstabelecimentoFinancas,
  EstabelecimentoInformacoes,
  EstabelecimentoProdutos,
  EstabelecimentoProfissionais,
  EstabelecimentoServicos,
} from './pages';

export enum EstabelecimentoTabsEnum {
  Informacoes = 0,
  Servicos = 1,
  Produtos = 2,
  Profissionais = 3,
  Administradores = 4,
  Agenda = 5,
  Financas = 6,
}

export interface IPropsEstabelecimentoPagina {
  estabelecimento: IEstabelecimentoView;
  refetch: () => any;
}

export const Estabelecimento: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(EstabelecimentoTabsEnum.Informacoes);
  const { usuarioId, tiposUsuario } = useUserContext();
  const { page } = useEstabelecimentoContext();

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { data: administradorEstabelecimento, isFetching: isLoadingAdministradorEstabelecimento } = useQuery(
    ['administradorEstabelecimento'],
    () => administradorEstabelecimentoService.getByUserId(usuarioId)
  );

  const {
    data: estabelecimento,
    refetch: refetchEstabelecimento,
    isFetching: isLoadingEstabelecimento,
  } = useQuery({
    queryKey: ['estabelecimento', administradorEstabelecimento],
    queryFn: () => {
      if (administradorEstabelecimento) {
        return estabelecimentoService.getById(administradorEstabelecimento.estabelecimentoId);
      }
    },
    enabled: !!administradorEstabelecimento,
  });

  const isLoading = useMemo(() => {
    return isLoadingAdministradorEstabelecimento || isLoadingEstabelecimento;
  }, [isLoadingAdministradorEstabelecimento, isLoadingEstabelecimento]);

  useEffect(() => {
    page && setTabIndex(page);
  }, [page]);

  const onChangeTab = useCallback(
    (_: any, index: number) => {
      setTabIndex(index);
    },
    [tabIndex]
  );

  return tiposUsuario.includes(TipoUsuarioEnum.AdministradorEstabelecimento) ? (
    <LayoutBase>
      <LayoutCadastro
        header={
          <Tabs value={tabIndex} onChange={onChangeTab} variant='scrollable'>
            <Tab
              label='Informações'
              icon={<Icon>business</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
            <Tab
              label='Serviços'
              icon={<Icon>build</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
            <Tab
              label='Produtos'
              icon={<Icon>shopping_cart</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
            <Tab
              label='Profissionais'
              icon={<Icon>person</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
            <Tab
              label='Administradores'
              icon={<Icon>engineering</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
            <Tab
              label='Agenda'
              icon={<Icon>calendar_month</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
            <Tab
              label='Finanças'
              icon={<Icon>attach_money</Icon>}
              iconPosition={lgDown ? 'top' : 'start'}
            />
          </Tabs>
        }
        esconderBotaoSalvar
      >
        {isLoading &&
          <Box display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Box>
        }

        {tabIndex === EstabelecimentoTabsEnum.Informacoes && estabelecimento && (
          <EstabelecimentoInformacoes
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
          />
        )}

        {tabIndex === EstabelecimentoTabsEnum.Servicos && estabelecimento && (
          <EstabelecimentoServicos
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
          />
        )}

        {tabIndex === EstabelecimentoTabsEnum.Produtos && estabelecimento && (
          <EstabelecimentoProdutos
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
          />
        )}

        {tabIndex === EstabelecimentoTabsEnum.Profissionais && estabelecimento && administradorEstabelecimento && (
          <EstabelecimentoProfissionais
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
            administradorEstabelecimentoId={administradorEstabelecimento.id!}
          />
        )}


        {tabIndex === EstabelecimentoTabsEnum.Administradores && estabelecimento && administradorEstabelecimento && (
          <EstabelecimentoAdministradores
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
          />
        )}

        {tabIndex === EstabelecimentoTabsEnum.Agenda && estabelecimento && (
          <EstabelecimentoAgenda
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
          />
        )}

        {tabIndex === EstabelecimentoTabsEnum.Financas && estabelecimento && (
          <EstabelecimentoFinancas
            estabelecimento={estabelecimento}
            refetch={refetchEstabelecimento}
          />
        )}
      </LayoutCadastro>
    </LayoutBase>
  ) : (
    <Forbidden />
  );
};
