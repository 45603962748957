import dayjs from 'dayjs';
import { Scheduler, SchedulerFilterOptionsEnum } from '../../../shared/components/SconApp/scheduler/Scheduler';
import { IPropsProfissionalPagina } from '../AreaProfissional';

export const AreaProfissionalAgenda: React.FC<IPropsProfissionalPagina> = ({ profissional, refetch }) => {
  const horaInicio = () => {
    const horasInicio = profissional.profissionalEstabelecimentos.map(x => dayjs(x.horaInicio, 'HH:mm').hour());
    return Math.min(...horasInicio);
  };

  const horaFim = () => {
    const horasFim = profissional.profissionalEstabelecimentos.map(x => dayjs(x.horaFim, 'HH:mm').hour());
    return Math.min(...horasFim);
  };

  return (
    <>
      <Scheduler
        filterLabel='Estabelecimento'
        filterOptions={profissional.profissionalEstabelecimentos?.map(item => ({
          id: item.estabelecimento.id,
          nome: item.estabelecimento.nome
        })) || []}
        filterOptionType={SchedulerFilterOptionsEnum.Estabelecimento}
        profissionalId={profissional.id}
        horaInicio={horaInicio()}
        horaFim={horaFim()}
        refetch={refetch}
      />
    </>
  );
};