import {
  Avatar,
  Box,
  Grid,
  Icon,
  IconButton,
  Paper,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TipoUsuarioEnum,
  useEstabelecimentoContext,
  useUserContext,
} from '../../shared/contexts';
import { LayoutBase } from '../../shared/layouts';
import { authService } from '../../shared/services/api/authService';
import {
  HomeAgendamentos,
  HomeWidgetProfissional,
  HomeWidgetAdministradorEstabelecimento,
} from './pages';

export const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { fotoUsuario, tiposUsuario } = useUserContext();
  const { setPage } = useEstabelecimentoContext();

  const onClickAtalho = useCallback(
    (redirect: string, page: number) => {
      setPage(page);
      navigate(redirect);
    },
    [setPage, navigate]
  );

  const {
    data: usuario,
    refetch,
    isFetching,
  } = useQuery(['usuario'], () => authService.getUserFromCookie());

  const formatarTelefone = (telefone?: string) => {
    if (!telefone || telefone.length < 11) {
      return '';
    }

    const numeroTelefone = telefone.replace(/\D/g, '');

    const codigoArea = numeroTelefone.substring(0, 2);
    const parte1 = numeroTelefone.substring(3, 7);
    const parte2 = numeroTelefone.substring(7, 11);

    return `(${codigoArea}) 9${parte1}-${parte2}`;
  };

  return (
    <LayoutBase>
      <Grid container spacing={3}>
        <Grid item lg={4} xs={12}>
          <Box display='flex' flexDirection='column' gap={3}>
            <Paper variant='outlined' sx={{ borderRadius: 2, padding: 2 }}>
              <Box display='flex' justifyContent='end' flex={1} marginRight={1}>
                <IconButton onClick={() => navigate('/perfil')}>
                  <Icon>edit</Icon>
                </IconButton>
              </Box>

              <Box display='flex' justifyContent='center' marginBottom={2}>
                <Avatar src={fotoUsuario} sx={{ height: 150, width: 150 }} />
              </Box>

              <Box display='flex' flexDirection='column' gap={1}>
                <Typography align='center' variant='h6'>
                  {isFetching ? <Skeleton /> : usuario?.nome}
                </Typography>

                <Typography align='center'>
                  {isFetching ? <Skeleton /> : usuario?.email}
                </Typography>

                <Typography align='center'>
                  {isFetching ? (
                    <Skeleton />
                  ) : (
                    formatarTelefone(usuario?.telefone)
                  )}
                </Typography>
              </Box>
            </Paper>

            {(tiposUsuario.includes(TipoUsuarioEnum.Profissional)
              || tiposUsuario.includes(TipoUsuarioEnum.AdministradorEstabelecimento))
              && (
                <Box
                  component={Paper}
                  variant='outlined'
                  height={lgDown ? undefined : 500}
                  padding={3}
                >
                  {tiposUsuario.includes(TipoUsuarioEnum.Profissional) &&
                    <HomeWidgetProfissional onClickAtalho={onClickAtalho} alwaysOpen={tiposUsuario.length == 1} />
                  }

                  {tiposUsuario.includes(TipoUsuarioEnum.AdministradorEstabelecimento) &&
                    <HomeWidgetAdministradorEstabelecimento onClickAtalho={onClickAtalho} alwaysOpen={tiposUsuario.length == 1} />
                  }
                </Box>
              )}
          </Box>
        </Grid>

        <Grid item lg={8} xs={12}>
          <HomeAgendamentos
            agendamentos={usuario?.agendamentos}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </LayoutBase>
  );
};
