import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef, } from 'react';
import * as Yup from 'yup';
import { VTextField } from '../../../shared/forms';
import getValidationErrors from '../../../shared/helpers/getValidationErrors';
import { LayoutCadastro } from '../../../shared/layouts';
import { administradorEstabelecimentoService } from '../../../shared/services';
import { feedback } from '../../../shared/services/alertService';

const schema = Yup.object().shape({
  email: Yup.string().required()
});

interface IProps {
  administradorEstabelecimentoId: number;
  onCloseEdit: () => void;
}

export const EstabelecimentoProfissionaisSconApp: React.FC<IProps> = ({ administradorEstabelecimentoId, onCloseEdit }) => {
  const formRef = useRef<FormHandles>(null);

  const { mutate: inviteProfessional, isLoading: isLoading } = useMutation((email: string) =>
    administradorEstabelecimentoService.inviteProfessional(administradorEstabelecimentoId, email), {
    onSuccess: () => {
      feedback('E-mail enviado com sucesso!', 'success');
      onCloseEdit();
    },
    onError: (error: any) => feedback(String(error), 'error'),
  });

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate({ email: data.email }, { abortEarly: false });

      inviteProfessional(data.email);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <LayoutCadastro header='Novo profissional SconApp' isLoading={isLoading} isSubmit>
        <Box display='flex' flexDirection='column' gap={2}>
          <VTextField name='email' label='Email do profissional' />
        </Box>
      </LayoutCadastro>
    </Form>
  );
};