import { IPropsEstabelecimentoPagina } from '../Estabelecimento';
import { Scheduler, SchedulerFilterOptionsEnum } from '../../../shared/components/SconApp/scheduler/Scheduler';
import dayjs from 'dayjs';

export const EstabelecimentoAgenda: React.FC<IPropsEstabelecimentoPagina> = ({ estabelecimento, refetch }) => {
  return (
    <>
      <Scheduler
        estabelecimentoId={estabelecimento.id}
        filterLabel='Profissonal'
        filterOptions={estabelecimento.profissionais?.map(item => ({
          id: item.id,
          nome: item.nome
        })) || []}
        filterOptionType={SchedulerFilterOptionsEnum.Profissional}
        horaInicio={dayjs(estabelecimento.horaInicio, 'HH:mm').hour()}
        horaFim={dayjs(estabelecimento.horaFim, 'HH:mm').hour()}
        refetch={refetch}
      />
    </>
  );
};  